import {Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ILabelValueRoute, TabSelectedCode, TabValue} from '../../../modules/profile/interfaces/TabSelectedCode';
import {LocalStorageVariables} from '../../../core';
import {Router} from '@angular/router';
import {PagesFacade} from '../../../pages/pages.facade';
import {Observable, Subscription, tap} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FeedbackModalComponent} from '../feedback-modal/feedback-modal.component';
import {AutoUnsubscribe} from 'ngx-ap-autounsubscribe';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {User} from '../../../modules/auth/models/User';
import {cloneDeep} from 'lodash';

@AutoUnsubscribe({arrayName: 'subscriptions'})
@Component({
    selector: 'app-icon-profile-user',
    templateUrl: './icon-profile-user.component.html',
    styleUrls: ['./icon-profile-user.component.scss']
})
export class IconProfileUserComponent implements OnInit {

    public items: Array<ILabelValueRoute> = [];
    public tabSelected$: Observable<ILabelValueRoute> = this.pagesFacade.getTabProfileSelected$();
    public subscriptions: Subscription[] = [];
    public user: User;
    public responsiveOn: boolean;
    public isBrowser: boolean;

    // @ts-ignore
    private subscription: Subscription = undefined;
    private document: Document;

    constructor(@Inject(DOCUMENT) document: Document,
                private router: Router,
                private pagesFacade: PagesFacade,
                private translateService: TranslateService,
                private modalService: NgbModal,
                @Inject(PLATFORM_ID) platformId: Object) {
        this.isBrowser = isPlatformBrowser(platformId);
        this.document = document;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.responsiveOn = window?.innerWidth <= 900;
    }

    ngOnInit(): void {
        this.items = Object.values(TabSelectedCode);
        const sb = this.pagesFacade.getUser$().subscribe(user => {
            if (user) {
                this.user = cloneDeep(user);
            }
        });
        this.subscriptions.push(sb);
        if (this.isBrowser) {
            this.responsiveOn = window?.innerWidth <= 900;
        }
    }

    public goToLogin() {
        this.router.navigate(['auth/login']);
    }

    public goToProfilePage(item: ILabelValueRoute): void {
        if (item?.value === TabValue.LOG_OUT) {
            this.logout();
        } else {
            this.items?.forEach(t => t.active = false);
            item.active = true;
            this.pagesFacade.setTabProfileSelected$(item);
            this.router.navigate([item.route]);
        }
    }

    public isUserLogged(): boolean {
        return !!this.document?.defaultView?.localStorage?.getItem(LocalStorageVariables.ACCESS_TOKEN);
    }

    private logout(): void {
        const modalRef = this.modalService.open(FeedbackModalComponent, {centered: true});
        modalRef.componentInstance.icon = 'assets/images/modal-feedback/icn_modale_logout.svg';
        modalRef.componentInstance.title = this.translateService.instant('AUTH.GENERAL.LOGOUT');
        modalRef.componentInstance.textOkButton = this.translateService.instant('GENERAL.CONFIRM');
        modalRef.componentInstance.visibleCancelBtn = true;
        this.subscription = modalRef.closed.pipe(
            tap(_ => this.pagesFacade.logout())
        ).subscribe();
    }

}
