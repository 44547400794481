export class RegistrationModel {
    public email: string;
    public password: string;
    public confirmedPassword: string;
    public optin: boolean;
    public acceptedPrivacyPolicy: boolean;


    constructor(email?: string, password?: string, confirmedPassword?: string, optin?: boolean, acceptedPrivacyPolicy?: boolean) {
        this.email = email;
        this.password = password;
        this.confirmedPassword = confirmedPassword;
        this.optin = optin;
        this.acceptedPrivacyPolicy = acceptedPrivacyPolicy;
    }
}
