import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Menu} from '../../models/menu';
import {Root} from '../../models/MegaMenu';
import {PagesFacade} from '../../../pages/pages.facade';
import {Subscription, tap, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BaseComponent} from '../../../core';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent extends BaseComponent implements OnInit {

    public menuItems: Menu[];
    public subscriptions: Array<Subscription> = [];
    public mainMenuToggle = false;
    public menuSkeleton: boolean;

    constructor(private pagesFacade: PagesFacade,
                private router: Router,
                public translateService: TranslateService,
                private cdr: ChangeDetectorRef) {
        super(translateService);
    }

    ngOnInit(): void {
        this.getMenu();
    }

    mainMenuToggleAction(): void {
        this.mainMenuToggle = !this.mainMenuToggle;
    }

    // Click Toggle menu (Mobile)
    toggletNavActive(item) {
        item.active = !item.active;
    }

    // PRIVATE METHOD

    private getMenu(): void {
        this.menuSkeleton = true;
        const sb = this.pagesFacade.getMenu().pipe(
            tap((response: Root) => {
                this.menuItems = Menu.buildMenuFullPageFromBO(response);
                this.menuSkeleton = false;
                this.cdr.detectChanges();
            }),
            catchError(error => {
                this.menuSkeleton = false;
                console.error('Error get menu data => ', error);
                return throwError(() => error);
            })
        ).subscribe();
        this.subscriptions?.push(sb);
    }

    public goToDynamicPath(menuItem: Menu) {
        this.router.navigate([menuItem?.path]);
    }
}
