import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Slider} from '../models/slider.model';
import {Configuration} from '../models/configuration.model';
import {ItemStructure} from '../../../shared/dynamic-loader/models/item-structure.model';
import {IPaginationParams} from '../../../shared/interfaces/IPaginationParams';

@Injectable({
    providedIn: 'root'
})
export class HomeService {

    private readonly ENDPOINT_V1: string = `${environment.addressPressupBackofficeAPI}${environment.api_v1}`;
    private readonly ENDPOINT_V2: string = `${environment.addressPressupBackofficeAPI}${environment.api_v2}`;

    constructor(private http: HttpClient) {
    }

    public getSliders(): Observable<Slider[]> {
        return this.http.get<Slider[]>(`${this.ENDPOINT_V1}/slider/index`);
    }

    public getTabDescriptionHome(): Observable<Configuration[]> {
        return this.http.get<Configuration[]>(`${this.ENDPOINT_V1}/configuration/index?key=tab_description_home`);
    }

    public getBestSellerHead(): Observable<Configuration[]> {
        return this.http.get<Configuration[]>(`${this.ENDPOINT_V1}/configuration/index?key=best_seller_header`);
    }

    public getSellerItems(): Observable<Configuration[]> {
        return this.http.get<Configuration[]>(`${this.ENDPOINT_V1}/configuration/index?key=best_seller_item`);
    }

    public getDiscoverPromotionHead(): Observable<Configuration[]> {
        return this.http.get<Configuration[]>(`${this.ENDPOINT_V1}/configuration/index?key=discover_promotion_header`);
    }

    public getDiscoverPromotionItems(): Observable<Configuration[]> {
        return this.http.get<Configuration[]>(`${this.ENDPOINT_V1}/configuration/index?key=discover_promotion_item`);
    }

    public getSearchedItems(searchParam: IPaginationParams): Observable<HttpResponse<Array<ItemStructure>>> {
        return this.http.get<Array<ItemStructure>>(`${this.ENDPOINT_V2}/menu-item/search?page=${searchParam.currentPage}&limit=${searchParam.pageItems}&terms=${searchParam.termToSearch}&isMinimal=true`, {observe: 'response'});
    }
}
