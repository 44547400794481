<section class="theme-invoice-2 section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-xl-9 m-auto">
                <div class="invoice-wrapper">
                    <div class="invoice-header">
                        <div class="row mt-3 mb-3">
                            <div class="col-md-6 col-12 mt-3 mb-3">
                                <img src="./assets/images/press-up/loghi/logo_pressup_rosso.svg" alt="img">
                            </div>
                            <div class="col-md-6 col-12 align-end mt-3 mb-3">
                                <div class="d-block">
                                    <h4 class="mb-2">
                                        {{'INVOICE.ADDRESS_1' | translate}}
                                    </h4>
                                    <h4 class="mb-2">
                                        {{'INVOICE.ADDRESS_2' | translate}}
                                    </h4>
                                    <h4 class="mb-0">
                                        {{'INVOICE.ADDRESS_3' | translate}}
                                    </h4>
                                </div>

                            </div>
                        </div>
                        <div class="row mt-3 mb-3 config-banner-color">
                            <div class="col-md-6 col-12 mt-3 mb-3">
                                <h3 class="color-white">
                                    FATTURA
                                </h3>
                                <p class="color-white">
                                    N 12345678
                                </p>
                            </div>
                            <div class="col-md-6 col-12 align-end mt-3 mb-3">
                                <img src="./assets/images/press-up/illustazioni/illustrazione_fattura.svg" class="w-50" alt="background">
                            </div>
                        </div>
                    </div>
                    <div class="invoice-body">
                        <div class="row">
                            <div class="col-md-3">
                                <span class="text-theme color-default font-weight-700 font-size-17-px">
                                    {{'INVOICE.BILING_ADRESS' | translate}}
                                </span>
                                <h4 class="mb-2">
                                    {{'INVOICE.ADDRESS_1' | translate}}
                                </h4>
                                <h4 class="mb-2">
                                    {{'INVOICE.ADDRESS_2' | translate}}
                                </h4>
                            </div>
                            <div class="col-md-2">
                                <span class="text-theme color-default font-weight-700 font-size-17-px">
                                    {{'INVOICE.DATE_OF_ISSUE' | translate}}:
                                </span>
                                <h4>20.06.2023</h4>
                            </div>
                            <div class="col-md-2">
                                <span class="text-theme color-default font-weight-700 font-size-17-px">
                                    {{'INVOICE.N_BILLING' | translate}}
                                </span>
                                <h4>202356311</h4>
                            </div>
                            <div class="col-md-2">
                                <span class="text-theme color-default font-weight-700 font-size-17-px">
                                    {{'INVOICE.N_ORDER' | translate}}
                                </span>
                                <h4>1234567</h4>
                            </div>
                            <div class="col-md-2">
                                <span class="text-theme color-default font-weight-700 font-size-17-px">
                                    {{'INVOICE.AMOUNT_DUE' | translate}}
                                </span>
                                <h4>2000,00 €</h4>
                            </div>
                        </div>
                        <p-table [value]="dataBody">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th *ngFor="let header of columnHeaders" class="header-column">
                                        {{ header | translate }}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                                <tr>
                                    <td>{{ rowIndex }}</td>
                                    <td>{{ data?.description }}</td>
                                    <td>{{ data?.copies }}</td>
                                    <td>{{ data?.total | currency:'EUR':true }}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="invoice-footer pt-0">
                        <div class="row">
                            <div class="col-md-7 col-12">
                                <p>
                                    {{'INVOICE.PAYED_WITH' | translate}}
                                </p>
                                <p class="color-black">
                                    Pay pal
                                </p>
                            </div>

                            <div class="col-md-5 col-12">
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <p class="color-black text-uppercase font-weight-700">
                                            {{'INVOICE.TOT_NET' | translate}}
                                        </p>
                                    </div>
                                    <div class="col-md-6 col-12 align-end">
                                        <p class="color-default font-bold">€ 800,00</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <p class="color-black text-uppercase font-weight-700">
                                            {{'INVOICE.VAT' | translate}}
                                        </p>
                                    </div>
                                    <div class="col-md-6 col-12 align-end">
                                        <p class="color-default font-bold">€ 100,00</p>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-6 col-12">
                                        <p class="color-black text-uppercase font-weight-700">
                                            {{'INVOICE.TOT_ORDER' | translate}}
                                        </p>
                                    </div>
                                    <div class="col-md-6 col-12 align-end">
                                        <p class="color-default font-bold">€ 900,00</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6 col-12 mb-3">
                                <a class="btn black-btn btn-outline rounded-2">
                                    {{'INVOICE.DOWNLOAD_PDF' | translate}}
                                </a>
                            </div>
                            <div class="col-md-6 col-12 mb-3 align-end">
                                <a class="btn btn-solid rounded-2" (click)="saveFile()">
                                    {{'INVOICE.PRINT' | translate}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
