export interface ILabelValueRoute {
    label: string;
    value: number;
    route: string;
    active?: boolean;
}

export enum TabValue {
    ACCOUNT_DATA = 0,
    SHIPPING_ADDRESSES = 1,
    ORDERS = 3,
    ARCHIVED_ORDERS = 4,
    CASHBACK = 5,
    NOTIFICATION = 6,
    ASSISTANCE = 7,
    UPDATE_PASSWORD = 8,
    LOG_OUT = 9
}

export let TabSelectedCode =  {
    ACCOUNT_DATA: {label: 'PAGES.PROFILE.ACCOUNT_DATA', value: TabValue.ACCOUNT_DATA, route: '/profile/account-data', active: true},
    SHIPPING_ADDRESSES: {label: 'PAGES.PROFILE.SHIPPING_ADDRESSES', value: TabValue.SHIPPING_ADDRESSES, route: '/profile/shipping-addresses'},
    ORDERS: {label: 'PAGES.PROFILE.ORDERS', value: TabValue.ORDERS, route: '/profile/orders'},
    ARCHIVED_ORDERS: {label: 'PAGES.PROFILE.ARCHIVED_ORDERS', value: TabValue.ARCHIVED_ORDERS, route: '/profile/archived-orders'},
    CASHBACK: {label: 'PAGES.PROFILE.CASHBACK', value: TabValue.CASHBACK, route: '/profile/cashback'},
    NOTIFICATION: {label: 'PAGES.PROFILE.NOTIFICATION_MANAGEMENT', value: TabValue.NOTIFICATION, route: '/profile/notification-management'},
    ASSISTANCE: {label: 'PAGES.PROFILE.ASSISTANCE', value: TabValue.ASSISTANCE, route: '/profile/assistance'},
    UPDATE_PASSWORD: {label: 'PAGES.PROFILE.UPDATE_PASSWORD', value: TabValue.UPDATE_PASSWORD, route: '/profile/update-password'},
    LOG_OUT: {label: 'PAGES.PROFILE.LOG_OUT', value: TabValue.LOG_OUT, route: '/auth/login'}
};
