import {ValidationFormNames} from '../enums/validation-form-names';
import {IEqualPasswordValidationRequest} from '../../shared/models/interfaces/IEqualPasswordValidationRequest';
import {StringOrUndefined} from '../types/generic-types';
import {validEmail, validPassword} from '../helpers/regex';


export class ValidationFormConfig {

    private static validationFormInstance: ValidationFormConfig;

    public static getInstance(): ValidationFormConfig {
        if (ValidationFormConfig.validationFormInstance) {
            return this.validationFormInstance;
        } else {
            ValidationFormConfig.validationFormInstance = new ValidationFormConfig();
            return this.validationFormInstance;
        }
    }
    public getErrorsFromField<T>(fieldsArray: Array<string>, value: T): Array<string> {
        return fieldsArray.map((validation: string) => {
            const errMessageCallback = this.getValidationFormMap<T>(value).get(validation);
            if (errMessageCallback) {
                return errMessageCallback(value);
            }
            return '';
        }).filter(el => el);
    }

    public getValidationFormMap<T>(value: T): Map<string, (val: T) => string> {
        return new Map<string, (val: T) => string>([
            [ValidationFormNames.REQUIRED, this.executeRequiredField.bind(value)],
            [ValidationFormNames.EMAIL, this.executeEmailValidation.bind(value)],
            [ValidationFormNames.PASSWORD, this.executePasswordValidation.bind(value)],
            [ValidationFormNames.EQUAL_PASSWORD, this.executeEqualPasswordValidation.bind(value)],
        ]);
    }

    private executeRequiredField<T>(target: T): string {
        if (!target) {
            return 'AUTH.VALIDATION.REQUIRED_FIELD';
        }
        if (typeof target == 'string' && (target as string)?.trim() == '') {
            return 'AUTH.VALIDATION.REQUIRED_FIELD';
        }
        return '';
    }

    private executeEmailValidation<T>(value: T): string {
        if (!validEmail((value as StringOrUndefined))) {
            return 'AUTH.VALIDATION.INVALID_FEMALE';
        }
        return '';
    }

    private executePasswordValidation<T>(value: T): string {
        if (!validPassword((value as StringOrUndefined))) {
            return 'AUTH.VALIDATION.INVALID_FEMALE';
        }
        return '';
    }

    private executeEqualPasswordValidation<T>(value: T): string {
        if ((value as IEqualPasswordValidationRequest)?.password !== (value as IEqualPasswordValidationRequest)?.confirmedPassword) {
            return 'AUTH.VALIDATION.PASSWORD_NOT_EQUALS';
        }
        return '';
    }
}
