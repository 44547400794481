import {Component, OnInit} from '@angular/core';
import {IInvoice} from './models/IInvoice';

@Component({
    selector: 'app-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

    public columnHeaders: Array<string> = [];
    public dataBody: Array<IInvoice> = [];

    constructor() {
    }

    ngOnInit(): void {
        this.columnHeaders.push(
            '#',
            'ORDERS.N_ORDERS',
            'ORDERS.DATE_ORDERS',
            'ORDERS.EXPECTED_DELIVERY'
        );
        this.populateDatatable();
    }

    public saveFile(): void {
        window.print();
    }

    // Private methods ...
    private populateDatatable(): void {
        this.dataBody.push(
            {
                description: 'Prodotto 1',
                copies: 25,
                total: 50
            },
            {
                description: 'Prodotto 2',
                copies: 25,
                total: 50
            },
            {
                description: 'Prodotto 3',
                copies: 25,
                total: 50
            }
        );
    }

}
