<form #f="ngForm">
    <ng-select notFoundText="{{ notFound | translate }}"
               #term="ngModel"
               [name]="name"
               [items]="items"
               [(ngModel)]="selectedItem"
               (change)="selectedItemChange($event)"
               [bindValue]="bindValue"
               [bindLabel]="bindLabel"
               [id]="selectId"
               (scrollToEnd)="scrollToEnd()"
               (clear)="resetList()"
               [loading]="loading"
               [loadingText]="loadingText"
               [virtualScroll]="virtualScroll"
               [multiple]="multiple"
               [editableSearchTerm]="true"
               dropdownPosition="bottom"
               placeholder="{{ placeholder | translate }}"
               class="custom-ng-select"
               [disabled]="disabled"
               [clearable]="clearable"
               [ngClass]="errorStyle ? 'input-error ' + customClass : customClass"
               (focus)="onFocus()"
               (open)="onOpen()"
               (close)="onClose()"
               [addTag]="addTag"
               [addTagText]="addTagText"
               [isOpen]="(hideDropdownIfEmptyOptions ? (items.length > 0 && isFocused) : undefined)">

        <ng-container *ngIf="headerTemplate">
            <ng-template ng-header-tmp>
                <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="itemTemplate">
            <ng-template ng-option-tmp let-item="item">
                <ng-container *ngTemplateOutlet="itemTemplate; context:{$implicit: item}"></ng-container>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="isOptionLabelToTranslate">
            <ng-template ng-label-tmp let-item="item">
                {{item[bindLabel] | translate}}
            </ng-template>
        </ng-container>

        <ng-template ng-option-tmp let-item="item">
            <div [matTooltip]="item && item[bindLabel]" matTooltipPosition="right"
                 class="text-overflow-hidden">{{getLabel(item)}}</div>
        </ng-template>
    </ng-select>
    <ng-container *ngIf="required">
        <small class="p-error" [hidden]="term?.untouched && !term?.dirty">
            <span *ngIf="term.errors?.required">{{ 'AUTH.VALIDATION.REQUIRED'| translate: {name: this.translate.instant('GENERAL.NO_PREDICTIONS')} }}</span>
        </small>
    </ng-container>
</form>
