<div class="card-body">
    <div class="absolute-img">
        <img src="assets/images/press-up/illustazioni/ill_indirizzi_vuoti.svg" class="img-fluid py-4" alt="">
    </div>
</div>
<h3 class="text-uppercase mb-1">
    {{'GENERAL.ALMOST_THERE' | translate}}
</h3>
<div class="col-12">
    {{ mex | translate}}
</div>
