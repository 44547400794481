export class AttachCarouselField {
    public isBrowser: boolean;
    public id: string;
    public config: any;

    constructor(isBrowser?: boolean, id?: string, config?: any) {
        this.isBrowser = isBrowser;
        this.id = id;
        this.config = config;
    }
}
