import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'app-banner-link',
    templateUrl: './banner-link.component.html',
    styleUrls: ['./banner-link.component.scss']
})
export class BannerLinkComponent implements OnInit, OnChanges {
    @Input() icon: string;
    @Input() title: string;
    @Input() text: string;
    @Input() button_text: string;
    @Input() button_link: string;

    constructor(private router: Router) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.icon = changes?.icon?.currentValue ?? this.icon;
        this.title = changes?.title?.currentValue ?? this.title;
        this.text = changes?.text?.currentValue ?? this.text;
        this.button_text = changes?.button_text?.currentValue ?? this.button_text;
        this.button_link = changes?.button_link?.currentValue ?? this.button_link;
    }

    ngOnInit(): void {
    }


    public goToLink(button_link: string): void {
        this.router.navigate([button_link]);
    }
}
