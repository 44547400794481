import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-page-in-work-template',
    templateUrl: './page-in-work-template.component.html',
    styleUrls: ['./page-in-work-template.component.scss']
})
export class PageInWorkTemplateComponent implements OnInit, OnChanges {

    @Input() mex: string;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.mex = changes?.mex?.currentValue ?? this.mex;
    }
}
