import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {TranslationService} from '../../../core/i18n';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

interface LanguageFlag {
    lang: string;
    name: string;
    flag: string;
    active?: boolean;
}

@Component({
    selector: 'app-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

    @HostBinding('class') classes = '';
    @Input() iconType: '' | 'brand';

    language: LanguageFlag;
    languages: LanguageFlag[] = [
        {
            lang: 'it',
            name: this.translateService.instant('GENERAL.ITALIAN'),
            flag: './assets/images/flags/021-italy.svg'
        },
        {
            lang: 'en',
            name: this.translateService.instant('GENERAL.ENGLISH'),
            flag: './assets/images/flags/012-uk.svg'
        }
    ];

    constructor(private translationService: TranslationService,
                private translateService: TranslateService,
                private router: Router) {
    }

    ngOnInit() {
        this.setSelectedLanguage();
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(_event => {
                this.setSelectedLanguage();
            });
    }

    setLanguage(lang) {
        this.languages.forEach((language: LanguageFlag) => {
            if (language.lang === lang) {
                language.active = true;
                this.language = language;
            } else {
                language.active = false;
            }
        });
        this.translationService.setLanguage(lang);
    }

    setSelectedLanguage(): any {
        this.setLanguage(this.translationService.getSelectedLanguage());
    }
}
