const errorMessages = new Map([
    [ 'blank', 'ERRORS_MAP.REQUIRED'],
    [ 'too_short', 'ERRORS_MAP.TOO_SHORT'],
    [ 'invalid', 'ERRORS_MAP.INVALID'],
    [ 'not_a_number', 'ERRORS_MAP.NOT_NUMBER'],
    [ 'wrong_length', 'ERRORS_MAP.WRONG_LENGTH'],
    [ 'inclusion', 'ERRORS_MAP.INVALID_TYPE'],
    [ 'taken', 'ERRORS_MAP.ALREADY_USED'],
    [ 'vatNumber', 'ERRORS_MAP.VAT_NOT_VALID'],
    [ 'businessCode', 'ERRORS_MAP.TAX_ID_NOT_VALID'],
    [ 'company', 'ERRORS_MAP.BUSINESS_NAME_NOT_VALID'],
    [ 'pec', 'ERRORS_MAP.PEC_NOT_VALID'],
    [ 'uniqueCode', 'ERRORS_MAP.UNIQUE_CODE_NOT_VALID'],
]);

export function errorMapping(key: string): string {
    return errorMessages.get(key) ?? key;
}
