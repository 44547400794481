<footer [class]="class">
    <div class="light-layout" *ngIf="newsletter">
        <div class="container">
            <section class="border-section border-top-0 py-3">
                <div class="row align-items-center">
                    <div class="col-lg-6 justify-content-center d-flex subscribe">
                        <div>
                            <h4 class="text-lowercase capitalize-first-letter footer-title">{{ 'AUTH.FOOTER.SUBSCRIBE_THE_NEWSLETTER' | translate }}</h4>
                            <h6 class="footer-font-simple-text"> {{ 'AUTH.FOOTER.DATA_NOT_SEND' | translate }}</h6>
                        </div>
                    </div>
                    <div class="col-lg-6 justify-content-center">
                        <form class="form-inline align-items-form display-responsive"
                              novalidate="novalidate"
                              id="kt_subscribe_newsletter_form" (ngSubmit)="submit()">
                            <div class="form-group mx-xs-0 mx-sm-3">
                                <div class="input-margin-b-auth">
                                    <input style="margin-bottom: unset; border-radius: 10px" type="text"
                                           class="form-control h-42px news-letter-input" id="email"
                                           name="email"
                                           [(ngModel)]="registrationValues.email"
                                           placeholder="{{'AUTH.REGISTER.EMAIL' | translate}}" required #email="ngModel"
                                           (keyup)="resetFieldValidation(registrationFields.EMAIL)"
                                           autocomplete="off"
                                           [ngClass]="isEmptyErrorByField(registrationFields.EMAIL)  ? '' : 'input-error-text'"/>
                                    <small class="p-error" [hidden]="isEmptyErrorByField(registrationFields.EMAIL)">
                                        <span *ngIf="!isEmptyErrorByField(registrationFields.EMAIL)">{{ getFirstErrorByField(registrationFields.EMAIL) | translate : {name: 'AUTH.INPUT.EMAIL' | translate} }}</span>
                                    </small>
                                    <div class="align-items-center" style="margin-bottom: 14px">
                                        <input type="checkbox" class="mt-1 me-2 custom-input" name="acceptPrivacyPolicy"
                                               value="privacyPolicyAccepted"
                                               [(ngModel)]="registrationValues.acceptPrivacyPolicy"
                                               [ngClass]="registrationValues.acceptPrivacyPolicy === false ? 'input-error-checkbox' : ''">
                                        <span class="text-muted footer-font-simple-text">{{ 'AUTH.FOOTER.ACCEPT_PRIVACY' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                            <button type="submit"
                                    class="btn btn-solid min-w-120px">{{ 'GENERAL.SUBSCRIBE' | translate }}
                            </button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <section class="light-layout p-3">
        <div class="container">
            <div class="row footer-theme partition-f justify-content-center">
                <div class="col-lg-3 col-md-6">
                    <div class="footer-contant">
                        <div class="footer-logo mb-0">
                            <img style="width: 90px" src="assets/images/loghi/pressUP/pressup_rosso.svg"
                                 alt="logo pressup">
                        </div>
                        <p class="footer-font-simple-text"
                           style="color: #777777;">{{ 'AUTH.FOOTER.VAT_NUMBER_FISCAL_CODE' | translate }}</p>
                        <p class="footer-font-simple-text" style="color: #777777;">IT10922761001</p>
                        <div class="footer-social">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/Pressup.it" target="_blank">
                                        <img style="width: 20px;" src="assets/images/social/facebook.svg"
                                             alt="logo facebook">
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/pressUp_it" class="social_link twitter"
                                       target="_blank">
                                        <img style="width: 20px;" src="assets/images/social/twitter.svg"
                                             alt="logo twitter">
                                    </a>
                                </li>
                                <li>
                                    <a href="http://www.youtube.com/pressup" class="social_link youtube"
                                       target="_blank">
                                        <img style="width: 20px;" src="assets/images/social/youtube.svg"
                                             alt="logo youtube">
                                    </a>
                                </li>
                                <li>
                                    <a href="http://it.pinterest.com/pressup/" class="social_link pinterest"
                                       target="_blank">
                                        <img style="width: 20px;" src="assets/images/social/pinterest.svg"
                                             alt="logo pinterest">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h6 class="text-lowercase capitalize-first-letter color-black fw-600 footer-font-title mb-0">{{ 'AUTH.FOOTER.SHIPMENTS' | translate }}</h6>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li>
                                    <a>
                                        <img style="width: 35px;" src="assets/images/loghi/bartolini.svg"
                                             alt="logo bartolini">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h6 class="text-lowercase capitalize-first-letter color-black fw-600 footer-font-title mb-0">{{ 'AUTH.FOOTER.PRINT' | translate }}</h6>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li class="mt-2 pt-0 clickable text-transform-none">
                                    <p class="footer-font-simple-text" routerLink="/printing-techniques">
                                        {{ 'AUTH.FOOTER.PRINTING_TECHNIQUES' | translate }}
                                    </p>
                                </li>
                                <li class="pt-0 clickable text-transform-none">
                                    <p class="footer-font-simple-text" routerLink="/sustainable-printing">
                                        {{ 'AUTH.FOOTER.SUSTAINABLE_PRINTING' | translate }}
                                    </p>
                                </li>
                                <li class="pt-0 cursor-hover text-transform-none">
                                    <p class="footer-font-simple-text" routerLink="/guidelines">
                                        {{ 'AUTH.FOOTER.FILE_GUIDELINES' | translate }}
                                    </p>
                                </li>
                                <li class="pt-0 clickable text-transform-none">
                                    <p class="footer-font-simple-text" routerLink="/faq">
                                        FAQ
                                    </p>
                                </li>
                                <li>
                                    <img style="width: 35px;" src="assets/images/loghi/fsc.svg" alt="logo phone"> &nbsp;
                                    <img style="width: 35px;" src="assets/images/loghi/pefc.svg" alt="logo phone">
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h6 class="text-lowercase capitalize-first-letter color-black fw-600 footer-font-title mb-0">
                                Policy</h6>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li class="pt-0 cursor-hover text-transform-none">
                                    <p class="footer-font-simple-text" routerLink="/cookie-policy">
                                        Cookie
                                    </p>
                                </li>
                                <li class="pt-0 cursor-hover text-transform-none">
                                    <p class="footer-font-simple-text" routerLink="/privacy">
                                        Privacy
                                    </p>
                                </li>
                                <li class="pt-0 cursor-hover text-transform-none">
                                    <p class="footer-font-simple-text" routerLink="/general_conditions_of_sale">
                                        {{ 'AUTH.FOOTER.GENERAL_CONDITIONS_OF_SALE' | translate }}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h6 class="cursor-hover text-lowercase capitalize-first-letter color-black fw-600 footer-font-title mb-0"
                                routerLink="/contact">{{ 'AUTH.FOOTER.CONTACTS' | translate }}</h6>
                        </div>
                        <div class="footer-contant">
                            <ul class="contact-list">
                                <li class="pt-0 ps-0 cursor-hover text-transform-none">
                                    <p class="footer-font-simple-text" routerLink="/aboutus">
                                        {{ 'AUTH.FOOTER.ABOUT_US' | translate }}
                                    </p>
                                </li>
                                <li class="pt-0 ps-0 cursor-hover text-transform-none">
                                    <p class="footer-font-simple-text" routerLink="/blog/left/sidebar">
                                        Blog
                                    </p>
                                </li>
                                <li class="pt-0 ps-0 d-flex align-items-end">
                                    <img style="width: 15px; margin-bottom: 4px;"
                                         src="assets/images/social/telefono.svg" alt="logo phone">
                                    <p class="footer-font-simple-text" style="margin-top: 5px; margin-left: 10px">
                                        076-15.21.351
                                    </p>
                                </li>
                                <li class="pt-0 ps-0 d-flex text-transform-none align-items-end">
                                    <img style="width: 15px;" src="assets/images/social/mail.svg"
                                         alt="logo mail">
                                    <a (click)="sendMail()" href="mailto:assistenza@pressup.it"
                                       style="margin-top: 5px; margin-left: 10px"
                                       class="clickable info_text footer-font-simple-text p-font">
                                        assistenza&#64;pressup.it
                                    </a>
                                </li>
                                <li class="pt-0 ps-0 d-flex align-items-end">
                                    <img style="width: 15px; margin-bottom: 4px;" src="assets/images/social/skype.svg"
                                         alt="logo skype">
                                    <p (click)="chatOnSkype()" class="clickable footer-font-simple-text"
                                       style="margin-top: 5px; margin-left: 10px">
                                        Skype
                                    </p>
                                </li>
                                <li class="pt-0 ps-0 d-flex align-items-end">
                                    <img style="width: 15px;margin-bottom: 4px;" src="assets/images/social/whatapp.svg"
                                         alt="logo whatsapp">
                                    <p (click)="sendTextMessageWA()"
                                       class="social_link whatsapp clickable footer-font-simple-text"
                                       style="margin-top: 5px; margin-left: 10px"
                                       onclick="fbq('track', 'Lead', {content_name: 'contatto-whatsapp'});ga('send', 'event', 'contatto-whatsapp', 'click')">
                                        WhatsApp
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p class="footer-font-simple-text"><i class="fa fa-copyright"
                                                              aria-hidden="true"></i> {{ today?.getFullYear() }} PressUP
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a><img width="35" src="assets/images/metodi_di_pagamento/visa.svg" alt="visa logo"></a>
                            </li>
                            <li>
                                <a><img width="35" src="assets/images/metodi_di_pagamento/american_express.svg"
                                        alt="American express logo"></a>
                            </li>
                            <li>
                                <a><img width="35" src="assets/images/metodi_di_pagamento/maestro.svg"
                                        alt="Maestro logo"></a>
                            </li>
                            <li>
                                <a><img width="35" src="assets/images/metodi_di_pagamento/paypal.svg" alt="Paypal logo"></a>
                            </li>
                            <li>
                                <a><img width="35" src="assets/images/metodi_di_pagamento/union_pay.svg"
                                        alt="Union pay logo"></a>
                            </li>
                            <li>
                                <a><img width="35" src="assets/images/metodi_di_pagamento/pago_bancomat.svg"
                                        alt="Bancomat logo"></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
