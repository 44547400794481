export class Credentials {

    public password?: string;
    public email?: string;

    constructor(password?: string, email?: string) {
        this.password = password;
        this.email = email;
    }

}
