import {Injectable} from '@angular/core';
import {CustomError, errorMapping, InfoFieldErrorModel} from '../../core';
import {first, isEmpty} from 'lodash';
import {HttpErrorResponse} from '@angular/common/http';

export type fieldValidationType = {
    [key: string]: Array<string> | undefined
};

@Injectable({
    providedIn: 'root'
})
export class SharedFormValidationService {

    public fieldsValidations: fieldValidationType = {};

    public validForm<T>(obj: T, callback: (obj: T) => Array<InfoFieldErrorModel>): boolean {
        let result = true;
        this.fieldsValidations = {};

        let resultCallback = callback(obj);

        resultCallback = resultCallback?.filter(el => el.hasError);

        resultCallback.forEach((value: InfoFieldErrorModel) => {
            result = false;
            this.fieldsValidations[value?.fieldName] = value?.errorMessage;
        });
        return result;
    }

    public getFirstErrorByField(field: string): string {
        return first(this.fieldsValidations[field]) ?? '';
    }

    public resetFieldValidation(field: string) {
        this.fieldsValidations[field] = undefined;
    }

    public resetAllFieldValidation() {
        this.fieldsValidations = {};
    }

    public isEmptyErrorByField(field: string): boolean {
        const errors: Array<string> | undefined = this.fieldsValidations[field];
        if (errors) {
            return isEmpty(errors);
        }
        return true;
    }

    public managementErrorFields(error: HttpErrorResponse): void {
        this.fieldsValidations = {};
        if (error?.status === 400 && !isEmpty(error?.error?.errors)) {
            const customErrors = error?.error?.errors as Array<CustomError>;
            customErrors?.forEach(customError => {
                const field = this.fieldsValidations[`${customError.field}`];
                if (field) {
                    field.push(errorMapping(customError?.error_code));
                } else {
                    this.fieldsValidations = {...this.fieldsValidations, ...{[`${customError.field}`]: [errorMapping(customError?.field)]}};
                }
            });
        }
    }
}
