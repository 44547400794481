import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-delete-item-modal',
    templateUrl: './delete-item-modal.component.html',
    styleUrls: ['./delete-item-modal.component.scss']
})
export class DeleteItemModalComponent {
    title: string;
    subTitle: string;
    textDeleteButton = 'GENERAL.DELETE';
    textCancelBtn = 'GENERAL.CANCEL';
    visibleCancelBtn: boolean;
    icon: string;

    constructor(public translateService: TranslateService,
                public activeModal: NgbActiveModal) {
    }

    public successAction(): void {
        this.activeModal.close();
    }

    public cancelAction(): void {
        this.activeModal.dismiss();
    }
}
