import {isEmpty} from 'lodash';

export class CustomError {

    error_code: string;
    status?: number;
    field?: string;

    constructor(error_code: string, status?: number, field?: string) {
        this.error_code = error_code;
        this.status = status;
        this.field = field;
    }

    static builderSameCustomErrorByFields(error_code: string, status: number, fields: Array<string>): Array<CustomError> {
        return fields.map(field => new CustomError(`${field}.${error_code}`, status, field));
    }

    static setErrors(customErrors: Array<CustomError>): Array<CustomError> {
        if (isEmpty(customErrors)) {
            return [];
        }

        return customErrors.map((element: CustomError) => this.setError(element));
    }

    static setError(customError: CustomError): CustomError {
        return customError?.field ?
            new CustomError(`${customError?.field}.${customError?.error_code}`, undefined, customError?.field) :
            new CustomError(`${customError?.error_code}`);
    }
}
