import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {ICustomParams} from '../models/interface/ICustomParams';
import {IDropdown} from '../models/interface/IDropdown';
import {BehaviorSubject, Observable} from 'rxjs';
import {first, isEmpty, uniqBy} from 'lodash';
import {Table} from 'primeng/table';
import {InfoFieldErrorModel} from '../models/info-field-error.model';
import {IValidFormParameter} from '../models/interface/IValidFormParameter';
import {CustomError} from '../models/CustomError';
import {errorMapping} from '../helpers/error-mapping';
import {isPlatformBrowser} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {isIt} from '../helpers/choose-lenguage';
import {AttachCarouselField} from '../../shared/dynamic-loader/models/attach-carousel-field';
import {MessageService} from 'primeng/api';

declare let $: any;
export type fieldValidationType = {
    [key: string]: Array<string> | undefined
};

export class BaseComponent {

    protected fieldsValidations: fieldValidationType = {};
    protected isBrowser: boolean;

    private _tableLoading: boolean;
    private _totalCount: number;
    private _pageItems: number;
    private _currentPage: number;
    private _totalPages: number;
    private _rowsPerPage = 10;
    private _initialParams: ICustomParams = {search: '', orsearch: ''};
    private _customParams: ICustomParams = {...this.initialParams};
    private _searchString: string;
    private _dropdownSelectLoading: boolean;
    private _nDropdown: Array<IDropdown> = [];
    private _genericLoading: boolean;
    public platformId: Object;
    private _dataLoaded = false;
    private _isDataLoadedSubject = new BehaviorSubject(false);
    private _customParamSubject = new BehaviorSubject<ICustomParams | undefined>(undefined);
    private _isDataLoadingSubject = new BehaviorSubject(false);
    private _isDisabledSubject = new BehaviorSubject(false);
    private _firstElementOfPage = 0;

    constructor(
        public translateService: TranslateService,
        platformId?: Object) {
        this.platformId = platformId;
    }

    get initialParams(): ICustomParams {
        return this._initialParams;
    }

    set initialParams(value: ICustomParams) {
        this._initialParams = value;
    }

    get tableLoading(): boolean {
        return this._tableLoading;
    }

    set tableLoading(value: boolean) {
        this._tableLoading = value;
    }

    get totalCount(): number {
        return this._totalCount;
    }

    set totalCount(value: number) {
        this._totalCount = value;
    }

    get pageItems(): number {
        return this._pageItems;
    }

    set pageItems(value: number) {
        this._pageItems = value;
    }

    get currentPage(): number {
        return this._currentPage;
    }

    set currentPage(value: number) {
        this._currentPage = value;
    }

    get totalPages(): number {
        return this._totalPages;
    }

    set totalPages(value: number) {
        this._totalPages = value;
    }

    get rowsPerPage(): number {
        return this._rowsPerPage;
    }

    set rowsPerPage(value: number) {
        this._rowsPerPage = value;
    }

    get customParams(): ICustomParams {
        return this._customParams;
    }

    set customParams(value: ICustomParams) {
        this._customParams = value;
    }

    get searchString(): string {
        return this._searchString;
    }

    set searchString(value: string) {
        this._searchString = value;
    }

    get dropdownSelectLoading(): boolean {
        return this._dropdownSelectLoading;
    }

    set dropdownSelectLoading(value: boolean) {
        this._dropdownSelectLoading = value;
    }

    get nDropdown(): Array<IDropdown> {
        return this._nDropdown;
    }

    set nDropdown(value: Array<IDropdown>) {
        this._nDropdown = value;
    }

    get dataLoaded(): boolean {
        return this._dataLoaded;
    }

    set dataLoaded(value: boolean) {
        this._dataLoaded = value;
    }

    get genericLoading(): boolean {
        return this._genericLoading;
    }

    set genericLoading(value: boolean) {
        this._genericLoading = value;
    }

    get firstElOfPage(): number {
        return this._firstElementOfPage;
    }

    set firstElOfPage(value: number) {
        this._firstElementOfPage = value;
    }

    public getLabel(object: any): string {
        return isIt(this.translateService?.currentLang) ? object.label_it : object?.label_en;
    }

    public getLabelDynamic(object: any, fieldName: string): string {
        return object?.[`${fieldName}_${this.translateService?.currentLang}`] ?? '';
    }

    public isDataLoading$(): Observable<boolean> {
        return this._isDataLoadingSubject.asObservable();
    }

    public setIsLoadingData(value: boolean): void {
        this._isDataLoadingSubject.next(value);
    }

    public isDisabled$(): Observable<boolean> {
        return this._isDisabledSubject.asObservable();
    }

    public setIsDisabled(value: boolean): void {
        this._isDisabledSubject.next(value);
    }

    public params$(): Observable<ICustomParams | undefined> {
        return this._customParamSubject.asObservable();
    }

    public getParams(): ICustomParams | undefined {
        return this._customParamSubject.value;
    }

    public setParams(value: ICustomParams): void {
        this._customParamSubject.next(value);
    }

    public isDataLoaded$(): Observable<boolean> {
        return this._isDataLoadedSubject.asObservable();
    }

    public isDataLoaded(): boolean {
        return this._isDataLoadedSubject.value;
    }

    public setIsDataLoaded(value: boolean): void {
        this._isDataLoadedSubject.next(value);
    }

    public clearDate(table: Table) {
        this.customParams.search = '';
        this.customParams.orsearch = '';
        this.refreshTable(table);
    }

    public getReportTemplate(): string {
        return `Pagina ${this._currentPage} di ${this._totalPages}`;
    }

    public initialPage(table: Table) {
        this.customParams.page = 1;
        this.refreshTable(table);
    }

    public resetEachKeyCustomParams(): void {
        Object.keys(this.customParams).forEach(key => {
            if (this.customParams[key as keyof ICustomParams]) {
                (this.customParams[key as keyof ICustomParams] as string) = '';
            }
        });
    }

    public attachCarousel(data: AttachCarouselField) {
        if (data?.isBrowser) {
            $(data?.id).owlCarousel(data?.config);
        }
    }

    public check500StatusCode(code: number, call: string, messageService: MessageService): void {
        if (code === 500) {
            const title = this.translateService.instant('GENERAL.ERROR');
            const subTitle = this.translateService.instant('TOAST.GENERAL_CALL_ERROR', {call});
            messageService.add({severity: 'error', summary: title, detail: subTitle});
        }
    }

    protected setDropDown(type: string) {
        this._nDropdown.push({type, pageIterate: 1} as IDropdown);
        this.nDropdown = uniqBy(this._nDropdown, 'type');
    }


    protected setHeaders<T>(data: HttpResponse<T>, totalCount?: number) {
        this.currentPage = (data?.headers?.get('Current-Page') as unknown as number);
        this.pageItems = (data?.headers?.get('Page-Items') as unknown as number);
        this.totalPages = (data?.headers?.get('Total-Pages') as unknown as number);
        this.totalCount = totalCount ? totalCount : (data?.headers?.get('Total-Count') as unknown as number);
    }

    protected getActivePage(firstPage?: number | undefined): number {
        return ((firstPage ?? 0) / this._rowsPerPage) + 1;
    }

    protected refreshTable(table: Table) {
        table?.reset();
    }

    protected validForm<T>(obj: T, callback: (obj: T) => Array<InfoFieldErrorModel>): boolean {
        let result = true;
        this.fieldsValidations = {};

        let resultCallback = callback(obj);

        resultCallback = resultCallback.filter(el => el.hasError);

        resultCallback.forEach((value: InfoFieldErrorModel) => {
            result = false;
            this.fieldsValidations[value?.fieldName] = value?.errorMessage;
        });
        return result;
    }

    protected validForms<T>(formsToValidate: Array<IValidFormParameter<T>>): Array<boolean> {
        let errors = {};
        const results: Array<boolean> = [];

        formsToValidate.forEach(data => {
            const result = this.validForm(data.obj, data.callback);
            results.push(result);
            if (!result) {
                errors = {...errors, ...this.fieldsValidations};
            }
        });
        this.fieldsValidations = errors;
        return results;
    }

    protected getFirstErrorByField(field: string): string {
        return first(this.fieldsValidations[field]) ?? '';
    }

    protected resetFieldValidation(field: string) {
        this.fieldsValidations[field] = undefined;
    }

    protected isEmptyErrorByField(field: string): boolean {
        const errors: Array<string> | undefined = this.fieldsValidations[field];
        if (errors) {
            return isEmpty(errors);
        }
        return true;
    }

    protected managementErrorFields(error: HttpErrorResponse): void {
        this.fieldsValidations = {};
        if (error?.status === 400 && !isEmpty(error?.error?.errors)) {
            const customErrors = error?.error?.errors as Array<CustomError>;
            customErrors?.forEach(customError => {
                const field = this.fieldsValidations[`${customError.field}`];
                if (field) {
                    field.push(errorMapping(customError?.error_code));
                } else {
                    this.fieldsValidations = {...this.fieldsValidations, ...{[`${customError.field}`]: [errorMapping(customError?.error_code)]}};
                }
            });
        }
    }

    protected getItemFromWindow(key: any): Object | undefined {
        if (isPlatformBrowser(this.platformId)) {
            return window[key];
        } else {
            return undefined;
        }
    }

    protected setItemInWindow(key: string, value: any): void {
        if (isPlatformBrowser(this.platformId)) {
            window[key] = value;
        }
    }
}
