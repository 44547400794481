export enum AccountDataEnum {
    NAME = 'Name',
    LASTNAME = 'Lastname',
    PHONE = 'Phone',
    SOCIETY = 'Society',
    SECTOR = 'Sector',
    VAT = 'Vat',
    TAX_ID_CODE = 'Tax id code',
    BUSINESS_NAME = 'Businessname',
    PEC = 'Pec',
    UNIQUE_CODE = 'Unique code',
    COUNTRY = 'Country',
    COUNTY = 'County',
    CAP = 'Cap',
    LOCATION = 'Location',
    ADDRESS = 'Address',
    COUNTRY_DELIVERY_ADDRESS = 'Delivery address country',
    COUNTY_DELIVERY_ADDRESS = 'Delivery address county',
    CAP_DELIVERY_ADDRESS = 'Delivery address cap',
    LOCATION_DELIVERY_ADDRESS = 'Delivery address location',
    ADDRESS_DELIVERY_ADDRESS = 'Delivery address address',
    HOW_KNOW_US = 'How know us'
}
