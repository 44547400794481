import {Directive, Input, SimpleChanges} from '@angular/core';
import {Avatar} from 'primeng/avatar';

@Directive({
    selector: '[appGetInitials]'
})
export class GetInitialsDirective {

    @Input() wordsToTakeInitials: Array<string>;

    constructor(private elRef: Avatar) {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.wordsToTakeInitials = changes.wordsToTakeInitials?.currentValue ?? this.wordsToTakeInitials;
        this.manageNameAndSurname();
    }

    private manageNameAndSurname() {
        this.elRef.label = this.wordsToTakeInitials?.map(word => word?.charAt(0))?.join('')?.toUpperCase();
    }
}
