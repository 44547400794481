import * as i0 from '@angular/core';
import { Directive, Pipe, forwardRef, EventEmitter, Component, ChangeDetectionStrategy, Input, Output, ContentChild, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormsModule } from '@angular/forms';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
function BarRating_div_2_2_ng_template_0_Template(rf, ctx) {}
function BarRating_div_2_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, BarRating_div_2_2_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    const fractionTemplate_r5 = i0.ɵɵreference(5);
    i0.ɵɵproperty("ngTemplateOutlet", (ctx_r3.customFractionRating == null ? null : ctx_r3.customFractionRating.template) || fractionTemplate_r5);
  }
}
function BarRating_div_2_3_ng_template_0_Template(rf, ctx) {}
function BarRating_div_2_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, BarRating_div_2_3_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    const inactiveTemplate_r6 = i0.ɵɵreference(9);
    i0.ɵɵproperty("ngTemplateOutlet", (ctx_r3.customInActiveRating == null ? null : ctx_r3.customInActiveRating.template) || inactiveTemplate_r6);
  }
}
function BarRating_div_2_4_ng_template_0_Template(rf, ctx) {}
function BarRating_div_2_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, BarRating_div_2_4_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    const activeTemplate_r7 = i0.ɵɵreference(7);
    i0.ɵɵproperty("ngTemplateOutlet", (ctx_r3.customActiveRating == null ? null : ctx_r3.customActiveRating.template) || activeTemplate_r7);
  }
}
function BarRating_div_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵlistener("click", function BarRating_div_2_Template_div_click_0_listener($event) {
      const unit_r3 = i0.ɵɵrestoreView(_r2).$implicit;
      return i0.ɵɵresetView(unit_r3.click($event));
    })("mouseenter", function BarRating_div_2_Template_div_mouseenter_0_listener() {
      const unit_r3 = i0.ɵɵrestoreView(_r2).$implicit;
      return i0.ɵɵresetView(unit_r3.enter());
    });
    i0.ɵɵelementContainerStart(1, 7);
    i0.ɵɵtemplate(2, BarRating_div_2_2_Template, 1, 1, null, 8)(3, BarRating_div_2_3_Template, 1, 1, null, 8)(4, BarRating_div_2_4_Template, 1, 1, null, 9);
    i0.ɵɵelementContainerEnd();
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const unit_r3 = ctx.$implicit;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitch", unit_r3.state);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", ctx_r3.unitState.fraction);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", ctx_r3.unitState.inactive);
  }
}
function BarRating_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 11);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "rateTitle");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(2, 1, ctx_r3.nextRate + 1, ctx_r3.titles));
  }
}
function BarRating_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 12);
  }
}
function BarRating_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 13);
  }
}
function BarRating_ng_template_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 14);
  }
}
class ActiveRating {
  constructor(template) {
    this.template = template;
  }
}
ActiveRating.ɵfac = function ActiveRating_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ActiveRating)(i0.ɵɵdirectiveInject(i0.TemplateRef));
};
ActiveRating.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ActiveRating,
  selectors: [["", "ratingActive", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActiveRating, [{
    type: Directive,
    args: [{
      selector: '[ratingActive]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }];
  }, null);
})();
class InactiveRating {
  constructor(template) {
    this.template = template;
  }
}
InactiveRating.ɵfac = function InactiveRating_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || InactiveRating)(i0.ɵɵdirectiveInject(i0.TemplateRef));
};
InactiveRating.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: InactiveRating,
  selectors: [["", "ratingInactive", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InactiveRating, [{
    type: Directive,
    args: [{
      selector: '[ratingInactive]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }];
  }, null);
})();
class FractionRating {
  constructor(template) {
    this.template = template;
  }
}
FractionRating.ɵfac = function FractionRating_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FractionRating)(i0.ɵɵdirectiveInject(i0.TemplateRef));
};
FractionRating.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: FractionRating,
  selectors: [["", "ratingFraction", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FractionRating, [{
    type: Directive,
    args: [{
      selector: '[ratingFraction]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }];
  }, null);
})();
class BarRatingPipe {
  transform(value = 0, titles) {
    /** Initialize value with 0 in case of undefined */
    return titles[value] || value;
  }
}
BarRatingPipe.ɵfac = function BarRatingPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BarRatingPipe)();
};
BarRatingPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "rateTitle",
  type: BarRatingPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BarRatingPipe, [{
    type: Pipe,
    args: [{
      name: 'rateTitle'
    }]
  }], null, null);
})();

/** This allows support [(ngModel)] and ngControl. */
const RATING_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BarRating),
  multi: true
};
/** This allows control required validation. */
const RATING_VALUE_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => BarRating),
  multi: true
};
var BarRatingUnitState;
(function (BarRatingUnitState) {
  BarRatingUnitState["active"] = "active";
  BarRatingUnitState["inactive"] = "inactive";
  BarRatingUnitState["selected"] = "selected";
  BarRatingUnitState["fraction"] = "fraction";
})(BarRatingUnitState || (BarRatingUnitState = {}));
class BarRating {
  constructor(changeDetectorRef) {
    this.changeDetectorRef = changeDetectorRef;
    this.unitState = BarRatingUnitState;
    this.contexts = [];
    /** Maximal rating that can be given using this widget. */
    this.max = 5;
    /** A flag indicating if rating can be updated. */
    this.readOnly = false;
    /** Set the theme */
    this.theme = 'default';
    /** Show rating title */
    this.showText = false;
    /** Replace rate value with a title */
    this.titles = [];
    /** A flag indicating if rating is required for form validation. */
    this.required = false;
    /**
     * A stream that emits when a user is hovering over a given rating.
     * Event's payload equals to the rating being hovered over.
     */
    this.hover = new EventEmitter();
    /**
     * A stream that emits when a user stops hovering over a given rating.
     * Event's payload equals to the rating of the last item being hovered over.
     */
    this.leave = new EventEmitter();
    /**
     * A stream that emits when a user selects a new rating.
     * Event's payload equals to the newly selected rating.
     */
    this.rateChange = new EventEmitter(true);
    /**
     * A stream that forwards a bar rating click since clicks are not propagated
     */
    this.barClick = new EventEmitter();
  }
  ngOnChanges(changes) {
    if (changes.rate) {
      this.update(this.rate);
    }
  }
  ngOnInit() {
    this.contexts = Array.from({
      length: this.max
    }, (context, i) => ({
      state: BarRatingUnitState.inactive,
      click: () => this.handleClick(i + 1),
      enter: () => this.handleEnter(i + 1)
    }));
    this.updateState(this.rate);
  }
  update(newRate, internalChange = true) {
    if (!this.readOnly && !this.disabled && this.rate !== newRate) {
      this.rate = newRate;
      this.rateChange.emit(this.rate);
    }
    if (internalChange) {
      this.onChange(this.rate);
      this.onTouched();
    }
    this.updateState(this.rate);
  }
  /** Reset rate value */
  reset() {
    this.leave.emit(this.nextRate);
    this.updateState(this.rate);
  }
  updateState(nextValue) {
    /** Set rate value as text */
    this.nextRate = nextValue - 1;
    /** Set the rating */
    this.contexts = Array.from({
      length: this.max
    }, (context, index) => ({
      state: index + 1 <= nextValue ? BarRatingUnitState.selected : (index + 1 === Math.round(nextValue) && nextValue % 1) >= 0.5 ? BarRatingUnitState.fraction : BarRatingUnitState.inactive,
      click: () => this.handleClick(index),
      enter: () => this.handleEnter(index)
    }));
  }
  handleClick(value) {
    this.update(value + 1);
  }
  handleEnter(index) {
    if (!this.disabled && !this.readOnly) {
      /** Add selected class for rating hover */
      this.contexts.map((context, i) => {
        context.state = i <= index ? BarRatingUnitState.active : BarRatingUnitState.inactive;
      });
      this.nextRate = index;
      this.hover.emit(index);
    }
  }
  /** This is the initial value set to the component */
  writeValue(value) {
    this.update(value, false);
    this.changeDetectorRef.markForCheck();
  }
  validate(c) {
    return this.required && !c.value ? {
      required: true
    } : null;
  }
  onChange(_) {}
  onTouched() {}
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
}
BarRating.ɵfac = function BarRating_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BarRating)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
BarRating.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BarRating,
  selectors: [["bar-rating"]],
  contentQueries: function BarRating_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ActiveRating, 5);
      i0.ɵɵcontentQuery(dirIndex, InactiveRating, 5);
      i0.ɵɵcontentQuery(dirIndex, FractionRating, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.customActiveRating = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.customInActiveRating = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.customFractionRating = _t.first);
    }
  },
  inputs: {
    rate: "rate",
    max: "max",
    readOnly: "readOnly",
    theme: "theme",
    showText: "showText",
    titles: "titles",
    required: "required"
  },
  outputs: {
    hover: "hover",
    leave: "leave",
    rateChange: "rateChange",
    barClick: "barClick"
  },
  features: [i0.ɵɵProvidersFeature([RATING_VALUE_ACCESSOR, RATING_VALUE_VALIDATOR]), i0.ɵɵNgOnChangesFeature],
  decls: 10,
  vars: 9,
  consts: [["fractionTemplate", ""], ["activeTemplate", ""], ["inactiveTemplate", ""], [1, "br-units", 3, "mouseleave"], ["class", "br-unit", 3, "click", "mouseenter", 4, "ngFor", "ngForOf"], ["class", "br-text", 4, "ngIf"], [1, "br-unit", 3, "click", "mouseenter"], [3, "ngSwitch"], [4, "ngSwitchCase"], [4, "ngSwitchDefault"], [3, "ngTemplateOutlet"], [1, "br-text"], [1, "br-unit-inner", "br-fraction"], [1, "br-unit-inner", "br-active"], [1, "br-unit-inner", "br-inactive"]],
  template: function BarRating_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementStart(0, "div")(1, "div", 3);
      i0.ɵɵlistener("mouseleave", function BarRating_Template_div_mouseleave_1_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.reset());
      });
      i0.ɵɵtemplate(2, BarRating_div_2_Template, 5, 3, "div", 4);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(3, BarRating_div_3_Template, 3, 4, "div", 5);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(4, BarRating_ng_template_4_Template, 1, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(6, BarRating_ng_template_6_Template, 1, 0, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor)(8, BarRating_ng_template_8_Template, 1, 0, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      i0.ɵɵclassMapInterpolate1("br br-", ctx.customInActiveRating ? "" : ctx.theme, "");
      i0.ɵɵclassProp("br-readonly", ctx.readOnly)("br-disabled", ctx.disabled);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngForOf", ctx.contexts);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.showText);
    }
  },
  dependencies: [i1.NgForOf, i1.NgSwitch, i1.NgSwitchCase, i1.NgTemplateOutlet, i1.NgSwitchDefault, i1.NgIf, BarRatingPipe],
  styles: ["*[_ngcontent-%COMP%]{box-sizing:border-box}[_nghost-%COMP%]{--br-font-size: 16px;--br-gap: 0;--br-active-color: #EDB867;--br-inactive-color: #D2D2D2}.br[_ngcontent-%COMP%]{position:relative}.br-units[_ngcontent-%COMP%]{display:flex;flex-wrap:nowrap;gap:var(--br-gap)}.br-unit[_ngcontent-%COMP%]{font-size:var(--br-font-size);cursor:pointer;-webkit-font-smoothing:antialiased;text-rendering:auto}.br-unit-inner[_ngcontent-%COMP%]{position:relative}.br-readonly[_ngcontent-%COMP%]   .br-unit[_ngcontent-%COMP%], .br-disabled[_ngcontent-%COMP%]   .br-unit[_ngcontent-%COMP%]{cursor:default}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BarRating, [{
    type: Component,
    args: [{
      selector: 'bar-rating',
      providers: [RATING_VALUE_ACCESSOR, RATING_VALUE_VALIDATOR],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div class=\"br br-{{ customInActiveRating ? '' : theme }}\"\r\n     [class.br-readonly]=\"readOnly\"\r\n     [class.br-disabled]=\"disabled\">\r\n\r\n  <div class=\"br-units\" (mouseleave)=\"reset()\">\r\n\r\n    <div *ngFor=\"let unit of contexts\"\r\n         class=\"br-unit\"\r\n         (click)=\"unit.click($event)\"\r\n         (mouseenter)=\"unit.enter()\">\r\n\r\n      <ng-container [ngSwitch]=\"unit.state\">\r\n        <ng-template *ngSwitchCase=\"unitState.fraction\" [ngTemplateOutlet]=\"customFractionRating?.template || fractionTemplate\"></ng-template>\r\n        <ng-template *ngSwitchCase=\"unitState.inactive\" [ngTemplateOutlet]=\"customInActiveRating?.template || inactiveTemplate\"></ng-template>\r\n        <ng-template *ngSwitchDefault [ngTemplateOutlet]=\"customActiveRating?.template || activeTemplate\"></ng-template>\r\n      </ng-container>\r\n    </div>\r\n  </div>\r\n\r\n  <div *ngIf=\"showText\" class=\"br-text\">{{ nextRate + 1 | rateTitle: titles }}</div>\r\n</div>\r\n\r\n<ng-template #fractionTemplate>\r\n  <div class=\"br-unit-inner br-fraction\"></div>\r\n</ng-template>\r\n\r\n<ng-template #activeTemplate>\r\n  <div class=\"br-unit-inner br-active\"></div>\r\n</ng-template>\r\n\r\n<ng-template #inactiveTemplate>\r\n  <div class=\"br-unit-inner br-inactive\"></div>\r\n</ng-template>\r\n",
      styles: ["*{box-sizing:border-box}:host{--br-font-size: 16px;--br-gap: 0;--br-active-color: #EDB867;--br-inactive-color: #D2D2D2}.br{position:relative}.br-units{display:flex;flex-wrap:nowrap;gap:var(--br-gap)}.br-unit{font-size:var(--br-font-size);cursor:pointer;-webkit-font-smoothing:antialiased;text-rendering:auto}.br-unit-inner{position:relative}.br-readonly .br-unit,.br-disabled .br-unit{cursor:default}\n"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }];
  }, {
    rate: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    readOnly: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    showText: [{
      type: Input
    }],
    titles: [{
      type: Input
    }],
    required: [{
      type: Input
    }],
    hover: [{
      type: Output
    }],
    leave: [{
      type: Output
    }],
    rateChange: [{
      type: Output
    }],
    barClick: [{
      type: Output
    }],
    customActiveRating: [{
      type: ContentChild,
      args: [ActiveRating]
    }],
    customInActiveRating: [{
      type: ContentChild,
      args: [InactiveRating]
    }],
    customFractionRating: [{
      type: ContentChild,
      args: [FractionRating]
    }]
  });
})();
class BarRatingModule {}
BarRatingModule.ɵfac = function BarRatingModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BarRatingModule)();
};
BarRatingModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: BarRatingModule
});
BarRatingModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, FormsModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BarRatingModule, [{
    type: NgModule,
    args: [{
      declarations: [BarRating, BarRatingPipe, ActiveRating, InactiveRating, FractionRating],
      imports: [CommonModule, FormsModule],
      exports: [BarRating, ActiveRating, InactiveRating, FractionRating]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ActiveRating, BarRating, BarRatingModule, FractionRating, InactiveRating };
