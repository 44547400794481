import {StringOrUndefined} from '../types/generic-types';

export function validEmail(value: StringOrUndefined): boolean {
    if (value) {
        const regex = new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');
        return regex.test(value);
    } else {
        return false;
    }
}

export function validPassword(value: StringOrUndefined): boolean {
    if (value) {
        const regex = new RegExp('^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9])(?=[^#?!@$%^&*\n-]*[#?!@$%^&*-]).{8,}$');
        return regex.test(value);
    } else {
        return false;
    }
}
