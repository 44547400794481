export class InfoFieldErrorModel {
    hasError: boolean;
    fieldName: string;
    errorMessage: Array<string>;

    constructor(hasError: boolean, fieldName: string, errorMessage: Array<string> = []) {
        this.hasError = hasError;
        this.fieldName = fieldName;
        this.errorMessage = errorMessage;
    }
}
