import {Payload} from './payload.model';

export class Configuration {

    public createdAt?: string;
    public key?: string;
    public payload?: Payload;
    public updatedAt?: string;
    public _id?: string;

    constructor(createdAt?: string, key?: string, payload?: Payload, updatedAt?: string, _id?: string) {
        this.createdAt = createdAt;
        this.key = key;
        this.payload = payload;
        this.updatedAt = updatedAt;
        this._id = _id;
    }

    static builderConfiguration(configuration: Configuration): Configuration {
        const configurationNew = new Configuration();
        Object?.keys(configuration)?.forEach(key => configurationNew[key] = configuration[key]);
        return configurationNew;
    }

    static builderConfigurationTab2(): Configuration {
        const configurationNew = new Configuration();
        const payloadNew = new Payload();
        configurationNew._id = '2';
        configurationNew.payload = payloadNew;
        configurationNew.key = '';
        configurationNew.payload.title_it = 'I clienti si fidano di noi';
        configurationNew.payload.title_en = 'Customers trust us';
        return configurationNew;
    }
}
