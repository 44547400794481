import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductService} from '../../services/product.service';
import {Product} from '../../classes/product';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    public products: Product[] = [];
    public search = false;

    constructor(public productService: ProductService) {
        this.productService.cartItems.subscribe(response => this.products = response);
    }

    ngOnInit(): void {
    }

    get getTotal(): Observable<number> {
        return this.productService.cartTotalAmount();
    }

    public searchToggle() {
        this.search = !this.search;
    }

    public removeItem(product: any) {
        this.productService.removeCartItem(product);
    }
}
