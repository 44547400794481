export enum FormatTypes {
    ADDRESS = 'name',
    CITY = 'city',
    COUNTY = 'county',
    COUNTRY = 'country',
    NATIONALITY = 'nationality',
    COUNTRY_OF_RESIDENCE = 'country_of_residence'
}


export function getFormatWithCountryFormat(): Array<string> {
    return [FormatTypes.COUNTRY, FormatTypes.NATIONALITY, FormatTypes.COUNTRY_OF_RESIDENCE];
}
export function getFieldsToSearchWithoutFilters(): Array<string> {
    return [...getFormatWithCountryFormat(), FormatTypes.ADDRESS];
}
