import {PathType} from '../../../shared/models/path-type.model';

export class Payload {

    public title_it?: string;
    public title_en?: string;
    public heading_it?: string;
    public heading_en?: string;
    public subtitle_it?: string;
    public subtitle_en?: string;
    public id_link?: string;
    public uri?: string;
    public image_path?: PathType;
    public title?: string;
    public subtitle?: string;
    public btn_link?: string;
    public btn_text_en?: string;
    public btn_text_it?: string;
    public description_it?: string;
    public description_en?: string;


    constructor(title_it?: string, title_en?: string, heading_it?: string, heading_en?: string, subtitle_it?: string,
                subtitle_en?: string, id_link?: string, uri?: string, image_path?: PathType, title?: string, subtitle?: string,
                btn_link?: string, btn_text_en?: string, btn_text_it?: string, description_it?: string, description_en?: string) {
        this.title_it = title_it;
        this.title_en = title_en;
        this.heading_it = heading_it;
        this.heading_en = heading_en;
        this.subtitle_it = subtitle_it;
        this.subtitle_en = subtitle_en;
        this.id_link = id_link;
        this.uri = uri;
        this.image_path = image_path;
        this.title = title;
        this.subtitle = subtitle;
        this.btn_link = btn_link;
        this.btn_text_en = btn_text_en;
        this.btn_text_it = btn_text_it;
        this.description_it = description_it;
        this.description_en = description_en;
    }
}
