<app-ng-select [name]="'address-autocomplete'"
               [loading]="isLoadingSelect"
               [notFound]="'GENERAL.NO_PREDICTIONS'"
               [items]="predictions"
               [selectedItem]="selectedItem"
               [virtualScroll]="false"
               [bindLabel]="'label'"
               [initLoadItems]="refInitLoadItems"
               [searchingCallback]="refSearchingCallback"
               [placeholder]="placeholder"
               (focusEvent)="resetSelectedItem()"
               (loadItemsEvent)="setPredictions($event)"
               [returnFullObjectOnChanged]="true"
               (searchingEvent)="setPredictions($event)"
               (searchingParamsEvent)="changeVal($event)"
               (selectedItemChangeEvent)="changeSelectedItem($event)"
               (closeEvent)="setLastValue($event)"
></app-ng-select>
