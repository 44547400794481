import {Component, OnInit} from '@angular/core';
import {Menu, TagItem} from '../../models/menu';
import {Subscription, tap, throwError} from 'rxjs';
import {Root} from '../../models/MegaMenu';
import {catchError} from 'rxjs/operators';
import {PagesFacade} from '../../../pages/pages.facade';
import {BaseComponent} from '../../../core';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-left-menu',
    templateUrl: './left-menu.component.html',
    styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent extends BaseComponent implements OnInit {

    public menuItems: Menu[];
    public subscriptions: Array<Subscription> = [];
    public leftMenuToggle = false;
    public currentDate = new Date();
    private readonly BASE_URL = environment?.addressPressupBackoffice;


    constructor(public translateService: TranslateService,
                private pagesFacade: PagesFacade,
    ) {
        super(translateService);
    }

    ngOnInit(): void {
        this.getMenu();
    }

    public getTagForMenu(original: string | undefined) {
        return `${this.BASE_URL}${original}`;
    }

    leftMenuToggleAction(): void {
        this.leftMenuToggle = !this.leftMenuToggle;
    }

    // Click Toggle menu (Mobile)
    toggletNavActive(item) {
        item.active = !item.active;
    }

    onHover(menuItem) {
        if (window.innerWidth > 1200 && menuItem) {
            document.getElementById('unset').classList.add('sidebar-unset');
        } else {
            document.getElementById('unset').classList.remove('sidebar-unset');
        }
    }

    public checkTags(menuTag: TagItem): boolean {
        if (!menuTag?.to) {
            return menuTag?.from <= this.currentDate.toISOString();
        } else {
            return menuTag?.from <= this.currentDate.toISOString() && menuTag?.to >= this.currentDate.toISOString();
        }
    }

    // PRIVATE METHOD
    private getMenu(): void {
        const sb = this.pagesFacade.getMenu().pipe(
            tap((response: Root) => this.menuItems = Menu.buildMenuResponsivePageFromBO(response)),
            catchError(error => {
                console.error('Error get menu data => ', error);
                return throwError(() => error);
            })
        ).subscribe();
        this.subscriptions?.push(sb);
    }
}
