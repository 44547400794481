<div class="container">
    <div class="row">
        <div *ngFor="let card of cards" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div class="border-card-style border-radius-5 p-3">
                <div class="theme-card text-center border-radius-5">
                    <h2 class="font-bold text-dark" [innerHTML]="card?.title | translate"></h2>
                    <p class="text-dark fs-15-px">{{ card?.text | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
