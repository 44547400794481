export enum ValidationFormNames {
	REQUIRED = 'required',
	EMAIL = 'email',
    PASSWORD = 'password',
    EQUAL_PASSWORD = 'equalPassword',
	VAT_LENGTH = 'vatLength',
	PHONE_REQUIRED = 'phoneRequired',
	PHONE_FORMAT = 'phoneFormat',
    IBAN_VALID = 'ibanValid',
    BIC_VALID = 'bicValid',
    CFIS_VALID = 'cfisValid',
	VALID_DATE_BY_CFIS = 'validDateByCFIS',
    VALID_ISO_31661 = 'validIso31661',
    VALID_DATE = 'validDate'
}
