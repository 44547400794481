import {ItemBreadcrumb} from '../../shared/models/item-breadcrumb.model';

export function getItemsLogin(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'PAGES.PROFILE.TITLE',
            path: ''
        },
        {
            label: 'login',
            path: ''
        }
    ];
}

export function getCreatePassword(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'PAGES.PROFILE.TITLE',
            path: ''
        },
        {
            label: 'login',
            path: '/auth/login'
        },
        {
            label: 'AUTH.LOGIN.PASSWORD_RECOVERY',
            path: ''
        }
    ];
}

export function getForgetPassword(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'PAGES.PROFILE.TITLE',
            path: ''
        },
        {
            label: 'login',
            path: '/auth/login'
        },
        {
            label: 'AUTH.GENERAL.FORGOT_BUTTON',
            path: ''
        }
    ];
}

export function getItemsRegister(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'PAGES.PROFILE.TITLE',
            path: ''
        },
        {
            label: 'AUTH.LOGIN.REGISTRATION',
            path: ''
        }
    ];
}

export function getItemsAboutUs(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'Home',
            path: '/'
        },
        {
            label: 'AUTH.FOOTER.ABOUT_US',
            path: '/aboutus'
        }
    ];
}

export function getItemsSustainablePrinting(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'Home',
            path: '/'
        },
        {
            label: 'AUTH.FOOTER.SUSTAINABLE_PRINTING',
            path: '/pages/sustainable-printing'
        }
    ];
}

export function getItemsCashback(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'Home',
            path: '/'
        },
        {
            label: 'CASHBACK.TITLE',
            path: '/cashback-program'
        }
    ];
}

export function getItemsPrintingTec(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'Home',
            path: '/'
        },
        {
            label: 'AUTH.FOOTER.PRINTING_TECHNIQUES',
            path: '/printing-techniques'
        }
    ];
}

export function getItemsFaq(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'Home',
            path: '/'
        },
        {
            label: 'FAQ',
            path: '/pages/accordion'
        }
    ];
}

export function getItemDesignYourIdea(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'Home',
            path: '/'
        },
        {
            label: 'PAGES.STATIC_PAGES.DESIGN_YOUR_IDEA.TITLE',
            path: '/pages/design-your-idea'
        }
    ];
}

export function getItemPressUpNow(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'Home',
            path: '/'
        },
        {
            label: 'PAGES.STATIC_PAGES.PRESS_UP_NOW.TITLE',
            path: '/press-up-now'
        }
    ]
}

export function getItemsPrivacy(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'Home',
            path: '/'
        },
        {
            label: 'GENERAL.PRIVACY_POLICY',
            path: '/privacy'
        }
    ];
}

export function getItemsGeneralConditionsOfSale(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'Home',
            path: '/'
        },
        {
            label: 'PAGES.STATIC_PAGES.GENERAL_CONDITIONS_SALE.TITLE',
            path: '/general_conditions_of_sale'
        }
    ];
}

export function getItemsCookiePolicy(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'Home',
            path: '/'
        },
        {
            label: 'GENERAL.COOKIE_POLICY',
            path: '/cookie-policy'
        }
    ];
}

export function getItemsEstimate(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'Home',
            path: '/'
        },
        {
            label: 'PAGES.STATIC_PAGES.ESTIMATE.TITLE',
            path: '/pages/estimate'
        }
    ];
}

export function getGuidelines(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'Home',
            path: '/'
        },
        {
            label: 'PAGES.STATIC_PAGES.GUIDELINES.TITLE',
            path: '/guidelines'
        }
    ];
}

export function getTryItForAEuro(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'Home',
            path: '/'
        },
        {
            label: 'PAGES.STATIC_PAGES.FOR_A_EURO.TITLE',
            path: '/try-it-for-a-euro'
        }
    ];
}

export function getItemBlog(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'Home',
            path: '/'
        },
        {
            label: 'BLOG',
            path: ''
        }
    ]
}

export function getItemBlogDetails(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'Home',
            path: '/'
        },
        {
            label: 'BLOG',
            path: '/blog/left/sidebar'
        },
        {
            label: 'GENERAL.DETAIL',
            path: ''
        }
    ]
}

export function getItemsAccountData(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'PAGES.PROFILE.TITLE',
            path: '/profile/account-data'
        },
        {
            label: 'PAGES.PROFILE.ACCOUNT_DATA',
            path: '/profile/account-data'
        }
    ]
}

export function getItemsShippingAddress(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'PAGES.PROFILE.TITLE',
            path: '/profile/account-data'
        },
        {
            label: 'PAGES.PROFILE.SHIPPING_ADDRESSES',
            path: '/profile/shipping-addresses'
        }
    ]
}

export function getItemsBillingAddress(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'PAGES.PROFILE.TITLE',
            path: '/profile/account-data'
        },
        {
            label: 'PAGES.PROFILE.BILLING_ADDRESSES',
            path: '/profile/shipping-addresses'
        }
    ]
}

export function getItemsNotificationManagement(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'PAGES.PROFILE.TITLE',
            path: '/profile/account-data'
        },
        {
            label: 'PAGES.PROFILE.NOTIFICATION_MANAGEMENT',
            path: '/profile/notification-management'
        }
    ]
}

export function getItemsAssistance(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'PAGES.PROFILE.TITLE',
            path: '/profile/account-data'
        },
        {
            label: 'PAGES.PROFILE.ASSISTANCE',
            path: '/profile/assistance'
        }
    ]
}

export function getItemsCreateAssistance(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'PAGES.PROFILE.TITLE',
            path: '/profile/account-data'
        },
        {
            label: 'PAGES.PROFILE.ASSISTANCE',
            path: '/profile/assistance'
        },
        {
            label: 'ASSISTANCE.NEW_REPORT',
            path: '/profile/assistance/new-report'
        }
    ]
}

export function getItemsShippingAddressCreation(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'PAGES.PROFILE.TITLE',
            path: '/profile/account-data'
        },
        {
            label: 'PAGES.PROFILE.SHIPPING_ADDRESSES',
            path: '/profile/shipping-addresses'
        },
        {
            label: 'ADDRESS.NEW_SHIPPING_ADDRESS',
            path: '/profile/shipping-addresses/management-shipping-address/create'
        }
    ]
}

export function getItemsShippingAddressModify(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'PAGES.PROFILE.TITLE',
            path: '/profile/account-data'
        },
        {
            label: 'PAGES.PROFILE.SHIPPING_ADDRESSES',
            path: '/profile/shipping-addresses'
        },
        {
            label: 'ADDRESS.MODIFY_SHIPPING_ADDRESS',
            path: '/profile/shipping-addresses/management-shipping-address/modify'
        }
    ]
}

export function getItemsBillingAddressCreation(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'PAGES.PROFILE.TITLE',
            path: '/profile/account-data'
        },
        {
            label: 'PAGES.PROFILE.BILLING_ADDRESSES',
            path: '/profile/billing-data'
        },
        {
            label: 'ADDRESS.NEW_BILLING_ADDRESS',
            path: '/profile/billing-data/management-billing-data/create'
        }
    ]
}

export function getItemsBillingAddressModify(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'PAGES.PROFILE.TITLE',
            path: '/profile/account-data'
        },
        {
            label: 'PAGES.PROFILE.BILLING_ADDRESSES',
            path: '/profile/billing-data'
        },
        {
            label: 'ADDRESS.MODIFY_BILLING_ADDRESS',
            path: '/profile/billing-data/management-billing-data/modify'
        }
    ]
}

export function getItemsErrorPage(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'GENERAL.HOME',
            path: '/home'
        },
        {
            label: 'GENERAL.ERRORS.PAGE_NOT_FOUND',
            path: ''
        },
    ]
}

export function getItemsUpdatePassword(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'PAGES.PROFILE.TITLE',
            path: '/profile/account-data'
        },
        {
            label: 'PAGES.PROFILE.UPDATE_PASSWORD',
        }
    ]
}

export function getItemsOffers(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'GENERAL.HOME',
            path: '/home'
        },
        {
            label: 'PAGES.OFFERS.OFFERS',
            path: '/Offerte'
        }
    ];
}

export function getItemsSearch(): Array<ItemBreadcrumb> {
    return [
        {
            label: 'GENERAL.HOME',
            path: '/home'
        },
        {
            label: 'PAGES.SEARCH.SEARCH',
            path: ''
        }
    ];
}
