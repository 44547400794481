export interface IPhotonAdditionalFilter {
    country?: string;
    county?: string;
}

export function getPhotonQuerySearch(params?: IPhotonAdditionalFilter): string {
    if (params) {
        `${(params?.county) ? (params?.county + '+') : ''}${params?.country ?? ''}`;
    }
    return '';
}
