import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-whatsapp-contact',
    templateUrl: './whatsapp-contact.component.html',
    styleUrls: ['./whatsapp-contact.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhatsappContactComponent implements OnChanges {

    @Input() visible = true;

    ngOnChanges(changes: SimpleChanges): void {
        this.visible = changes?.visible?.currentValue ?? this.visible;
    }

}
