import {Component, OnInit, OnDestroy, ViewChild, TemplateRef, AfterViewInit, PLATFORM_ID, Inject} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {User} from '../../../../modules/auth/models/User';
import {PagesFacade} from '../../../../pages/pages.facade';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from 'ngx-ap-autounsubscribe';

@AutoUnsubscribe({arrayName: 'subscriptions'})
@Component({
    selector: 'app-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('newsletter', {static: false}) NewsLetterModal: TemplateRef<any>;

    public closeResult: string;
    public modalOpen = false;
    public subscriptions: Subscription[] = [];
    private user: User;
    private document: Document;

    constructor(@Inject(DOCUMENT) document: Document,
                @Inject(PLATFORM_ID) private platformId: Object,
                private modalService: NgbModal,
                private pagesFacade: PagesFacade) {
        this.document = document;
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        const sb = this.pagesFacade.getUser$().subscribe(user => {
            this.user = user;
        });
        this.subscriptions.push(sb);
        if (!this.user && this.document?.defaultView?.localStorage?.getItem('canOpenModalNewsletter') !== 'true') {
            this.openModal();
        }
    }

    ngOnDestroy() {
        if (this.modalOpen) {
            this.modalService.dismissAll();
        }
    }

    public openModal() {
        if (isPlatformBrowser(this.platformId)) { // For SSR
            this.modalService.open(this.NewsLetterModal, {
                size: 'lg',
                ariaLabelledBy: 'NewsLetter-Modal',
                centered: true,
                windowClass: 'theme-modal newsletterm NewsLetterModal'
            }).result.then((result) => {
                this.modalOpen = true;
                `Result ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    public dontEverShow() {
        this.document?.defaultView?.localStorage?.setItem('canOpenModalNewsletter', 'true');
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
}
