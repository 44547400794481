<section *ngIf="products" class="section-b-space ratio_asos">
    <div class="container">
        <div class="row mordern-box-margin clickable">
            <div class="col-lg-3 col-sm-12" *ngFor="let product of products">
                <div class="mordern-box stile-div-mt-mb border-radius-10">
                    <div class="absolute-img mb-4" style="height: 5rem">
                        <img [src]="product?.src" width="672" height="310" class="img-fluid height-img" alt="">
                    </div>
                    <div class="mordern-content">
                        <div class="mordern-bottom">
                            <div class="left mt-3">
                                <a>
                                    <h4 class="p-1 text-uppercase text-black">{{ product?.title | uppercase }}</h4>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section *ngIf="product" class="section-b-space ratio_asos">
    <div class="mordern-box-margin clickable">
        <div class="col-12">
            <div class="mordern-box mordern-box-single stile-div-mt border-radius-10">
                <div class="absolute-img mb-4" style="height: 5rem">
                    <img [src]="product?.src" class="img-fluid height-img" alt="">
                </div>
                <div class="mordern-content">
                    <div class="mordern-bottom">
                        <div class="left mt-3">
                            <h4 class="p-1 text-black">{{ product?.title | uppercase }}</h4>
                            <h3 class="p-1 color-default">{{ product?.bonus | uppercase }}</h3>
                            <h6 class="p-1 text-lowercase">{{ product?.message | uppercase }}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section *ngIf="productConf" class="section-b-space" style="padding-top: 45px">
    <div class="container">
        <div class="mordern-box-margin clickable" (click)="goToLink(productConf?.payload?.uri)">
            <div class="col-12">
                <div class="mordern-box mordern-box-single stile-div-mt border-radius-10">
                    <div class="absolute-img align-items-center d-flex justify-content-center">
                        <img [src]="getImg(productConf?.payload?.image_path?.original)"
                             class="img-fluid contain-image-dim"
                             alt="{{productConf?.payload?.image_path?.originalname}}">
                    </div>
                    <div class="mordern-content">
                        <div class="title-space">
                            <h4 class="p-1 text-black">{{ productConf?.payload?.title_it | uppercase }}</h4>
                        </div>
                    </div>
                    <div class="mordern-bottom">
                        <div class="tag-new-space">
                            <h3 class="p-1 color-default">{{ productConf?.payload?.subtitle_it | uppercase }}</h3>
                        </div>

                        <h6 class="p-1 text-lowercase">{{ productConf?.payload?.heading_it | uppercase }}</h6>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section *ngIf="item" class="section-b-space ratio_asos">
    <div class="container">
        <div class="mordern-box-margin clickable" (click)="goToLink(item?.uri)">
            <div class="col-12">
                <div class="mordern-box mordern-box-single stile-div-mt border-radius-10">
                    <div class="absolute-img align-items-center d-flex justify-content-center">
                            <img ngSrc="{{item?.preview_id_picture?.min ?? item?.id_picture?.min}}"
                                 placeholder="{{item?.preview_id_picture?.min ?? item?.id_picture?.min}}" width="252"
                                 height="116" class="img-fluid contain-image-dim"
                                 alt="{{item?.preview_id_picture?.originalname ?? item?.id_picture?.originalname}}"
                                 loading="lazy">
                    </div>
                    <div class="mordern-content">
                        <div class="title-space">
                            <h4>{{ getLabelDynamic(item, 'preview_title') | uppercase }}</h4>
                        </div>
                        <div class="sub-title-space mt-1">
                            <p class="text-lowercase">{{ getLabelDynamic(item, 'preview_subtitle') | uppercase }}</p>
                        </div>
                    </div>
                    <div class="mordern-bottom">

                        <div class="tag-new-space">
                            @if (checkValidityTagNew(item)) {
                                <h3 class="text-uppercase color-default">NEW</h3>
                            }
                        </div>
                        <h6 style="text-transform: none !important;">{{'HOME.SHIPPING_FREE' | translate}}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>