import {Component, OnInit, Input} from '@angular/core';
import {NewProductSlider} from '../../../data/slider';
import {Product} from '../../../classes/product';
import {ProductService} from '../../../services/product.service';
import {AttachCarouselField} from '../../../dynamic-loader/models/attach-carousel-field';
import {BaseComponent} from '../../../../core';
import {TranslateService} from "@ngx-translate/core";


@Component({
    selector: 'app-product-box-vertical-slider',
    templateUrl: './product-box-vertical-slider.component.html',
    styleUrls: ['./product-box-vertical-slider.component.scss']
})
export class ProductBoxVerticalSliderComponent extends BaseComponent implements OnInit {

    @Input() title = 'New Product'; // Default
    @Input() type = 'fashion'; // Default Fashion

    public products: Product[] = [];
    public isBrowser: boolean;

    constructor(public productService: ProductService,
                public translateService: TranslateService,) {
        super(translateService);
        this.productService.getProducts.subscribe(response =>
            this.products = response.filter(item => item.type == this.type)
        );
    }

    ngOnInit(): void {
        this.attachCarousel(this.buildAttachCarouselFiled());
    }

    private buildAttachCarouselFiled(): AttachCarouselField {
        const config = new AttachCarouselField();
        config.isBrowser = this.isBrowser;
        config.id = '.owl-carousel';
        config.config = NewProductSlider;
        return config;
    }

}
