import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {BaseComponent, RegistrationFieldsEnum} from '../../core';
import {RegistrationModel} from '../../modules/auth/models/registration.model';
import {TranslateService} from '@ngx-translate/core';
import {PagesFacade} from '../../pages/pages.facade';
import {Subscription} from 'rxjs';
import {User} from '../../modules/auth/models/User';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent extends BaseComponent implements OnInit {
    @Input() class = 'footer-light'; // Default class
    @Input() themeLogo = 'assets/images/icon/logo.png'; // Default Logo
    @Input() newsletter = true; // Default True
    public emailToInsert: string;
    public user: User;
    public registrationFields = RegistrationFieldsEnum;
    public registrationValues: RegistrationModel = new RegistrationModel();
    public today: Date = new Date();
    public subscriptions: Subscription[] = [];

    constructor(public translateService: TranslateService,
                private pagesFacade: PagesFacade) {
        super(translateService);
    }

    ngOnInit(): void {
        const sb = this.pagesFacade.getUser$().subscribe(user => {
            this.user = user;
        });
        this.subscriptions.push(sb);
        this.today.getFullYear();
    }

    public sendMail(): void {
        window.open('mailto:assistenza@pressup.it');
    }

    public chatOnSkype(): void {
        window.open('skype:pressup.it?chat', '_blank');
    }

    public sendTextMessageWA(): void {
        window.open('https://wa.me/393470845983?text=', '_blank');
    }

    public submit() {

    }
}
