<section class="section-b-space p-t-0" style="padding-bottom: {{padding_bottom}}" *ngIf="skeleton === SkeletonType.BOX">
    <div class="container">
        <div class="row">
            @for (skeleton of fakeSpinner; track skeleton) {
                <div class="col-md-{{col}} col-sm-12">
                    <p-skeleton height="{{height}}" styleClass="mb-2"></p-skeleton>
                </div>
            }
        </div>
    </div>
</section>

<section class="section-b-space p-t-0" style="padding-bottom: {{padding_bottom}}"
         *ngIf="skeleton === SkeletonType.TEXT">
    <div class="container">
        <div class="row" [ngClass]="centerTxt ? 'justify-content-center' : 'unset'">
            @for (skeleton of fakeSpinner; track skeleton) {
                <div class="col-md-{{col}} col-sm-12">
                    <p-skeleton height="{{height}}" styleClass="mb-2"></p-skeleton>
                </div>
            }
        </div>
    </div>
</section>