import {Component, OnInit, OnDestroy, ViewChild, TemplateRef, AfterViewInit, PLATFORM_ID, Inject} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';

@Component({
    selector: 'app-age-verification',
    templateUrl: './age-verification.component.html',
    styleUrls: ['./age-verification.component.scss']
})
export class AgeVerificationComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('ageVerification') AgeVerificationModal: TemplateRef<any>;

    public closeResult: string;
    public ageVerificationForm: UntypedFormGroup;
    public currdate: any;
    public setDate: any;

    private document: Document;

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                @Inject(DOCUMENT) document: Document,
                private modalService: NgbModal,
                private fb: UntypedFormBuilder) {
        this.ageVerificationForm = this.fb.group({
            day: ['', Validators.required],
            month: ['', Validators.required],
            year: ['', Validators.required],
        });
        this.document = document;
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        if (this.document?.defaultView?.localStorage?.getItem('ageVerification') !== 'true') {
            this.openModal();
        }
    }

    ngOnDestroy() {
    }

    openModal() {
        if (isPlatformBrowser(this.platformId)) { // For SSR
            this.modalService.open(this.AgeVerificationModal, {
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                centered: true,
                windowClass: 'bd-example-modal-md theme-modal agem'
            }).result.then((result) => {
                `Result ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    ageForm() {
        let day = this.ageVerificationForm.value.day;
        let month = this.ageVerificationForm.value.month;
        let year = this.ageVerificationForm.value.year;
        let age = 18;
        let mydate = new Date();
        mydate.setFullYear(year, month - 1, day);

        let currdate = new Date();
        this.currdate = currdate;
        let setDate = new Date();
        this.setDate = setDate.setFullYear(mydate.getFullYear() + age, month - 1, day);

        if ((this.currdate - this.setDate) > 0) {
            this.document?.defaultView?.localStorage.setItem('ageVerification', 'true');
            this.modalService.dismissAll();
        } else {
            window.location.href = 'https://www.google.com/';
        }
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

}
