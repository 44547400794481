import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, tap, throwError} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {LoginModel} from '../models/login.model';
import {get, isEmpty} from 'lodash';
import {IEqualPasswordValidationRequest} from '../../../shared/models/interfaces/IEqualPasswordValidationRequest';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AutoUnsubscribe} from 'ngx-ap-autounsubscribe';
import {Router} from '@angular/router';
import {ILoginResponse} from '../../../core/models/interface/ILoginResponse';
import {LocalStorageVariables} from '../../../core';
import {RegistrationModel} from '../models/registration.model';
import {InfoFieldErrorModel} from '../../../core';
import {
    ValidationFormConfig,
    ValidationFormNames,
    RegistrationFieldsEnum,
    IEqualPasswordRequestOrUndefined,
    StringOrUndefined
} from '../../../core';
import {Credentials} from '../../../core';
import {environment} from '../../../../environments/environment';
import {DOCUMENT} from '@angular/common';
import {User} from '../models/User';
import {AccountData} from '../../profile/models/account-data';
import {AccountDataEnum} from '../../profile/enums/account-data-enum';
import {UpdatePasswordModel} from '../../profile/models/update-password.model';
import {UpdatePasswordEnum} from '../../profile/enums/update-password-enum';
import {IForgotPassword} from '../models/interface/IForgotPassword';
import {GenericResponse} from '../models/generic.response';
import {CommunicationResponseModel} from '../../profile/models/communication-response.model';
import {MessageField} from '../../profile/enums/message-field';
import {NumberOrUndefined} from '../../../core/types/generic-types';
import {SocietyType} from '../../profile/enums/Society-type';
import {ShippingAddressEnum} from '../../profile/enums/shipping-address-enum';
import {AddressModel} from '../../profile/models/address.model';

@AutoUnsubscribe({arrayName: 'unsubscribe'})
@Injectable({
    providedIn: 'root',
})
export class AuthService {

    private document: Document;
    private readonly ENDPOINT_V1: string = `${environment.addressPressupAPIDEM}${environment.api_v1}`;
    private readonly EMAIL_PATH_IN_JWT = 'account.email';

    // public fields
    isLoading$: Observable<boolean>;
    isLoadingSubject: BehaviorSubject<boolean>;
    _currentUserSubject$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    constructor(@Inject(DOCUMENT) document: Document,
                private http: HttpClient,
                private router: Router) {
        this.isLoadingSubject = new BehaviorSubject<boolean>(false);
        this.isLoading$ = this.isLoadingSubject.asObservable();
        this.getUserByToken().subscribe();
        this.document = document;
    }

    // public methods

    public login(userCredential: Credentials): Observable<ILoginResponse> {
        this.isLoadingSubject.next(true);
        this.clearLocalStorage();
        const request$ = this.http.post<ILoginResponse>(`${this.ENDPOINT_V1}/User/Login`, userCredential);
        return request$.pipe(
            tap((loginResponse: ILoginResponse) => {
                this.document?.defaultView?.localStorage.setItem(LocalStorageVariables.ACCESS_TOKEN, loginResponse.token);
                this.document?.defaultView?.localStorage.setItem(LocalStorageVariables.REFRESH_TOKEN, loginResponse.refreshToken);
            }),
            catchError((err) => throwError(() => err)),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    public logout(): void {
        const refresh = this.getRefreshToken();
        if (refresh) {
            this.http.delete<any>(`${this.ENDPOINT_V1}/User/Refresh/Revoke?refreshToken=${refresh}`).pipe(
                catchError((err) => {
                    console.error('err during logout => ', err);
                    return throwError(() => err);
                }),
                finalize(() => {
                    this.clearLocalStorage();
                    this.router.navigate(['/auth/login']);
                })
            ).subscribe();
        } else {
            this.clearLocalStorage();
            this.router.navigate(['/auth/login']);
        }
        this.setUserValue$(null);
    }

    public getUserByToken(): Observable<User> {
        const accessToken = this.getJwtToken();
        if (!accessToken) {
            return of(undefined);
        }

        this.isLoadingSubject.next(true);
        return this.getUserInfo(accessToken).pipe(
            map((user: User) => {
                if (user) {
                    this._currentUserSubject$ = new BehaviorSubject<User>(user);
                } else {
                    this.logout();
                }
                return user;
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    public getUserInfo(token?: string): Observable<User> {
        if (!token) {
            return of(undefined);
        }
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        return this.http.get<User>(`${this.ENDPOINT_V1}/User`, {
            headers: httpHeaders,
        }).pipe(
            tap());
    }

    public registration(registrationRequest: RegistrationModel): Observable<void> {
        this.isLoadingSubject.next(true);
        return this.http.post<void>(`${this.ENDPOINT_V1}/User/Register`, registrationRequest).pipe(
            catchError((err) => {
                console.error('err during registration => ', err);
                return throwError(() => err);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    public confirmAccount(token: string): Observable<GenericResponse> {
        const headers = new HttpHeaders({
            accept: 'text/plain'
        });
        return this.http.get<GenericResponse>(`${this.ENDPOINT_V1}/User/Register/Validation?token=${token}`, {headers});
    }

    public forgotPassword(email: string): Observable<IForgotPassword> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        this.isLoadingSubject.next(true);
        return this.http.post<IForgotPassword>(`${this.ENDPOINT_V1}/User/ResetPassword`, JSON.stringify(email), {headers});
    }

    public getJwtToken() {
        return this.document?.defaultView?.localStorage?.getItem(LocalStorageVariables.ACCESS_TOKEN);
    }

    public storeJwtToken(jwt: string) {
        this.document?.defaultView?.localStorage?.setItem(LocalStorageVariables.ACCESS_TOKEN, jwt);
    }

    public storeRefreshToken(refreshToken: string) {
        this.document?.defaultView?.localStorage?.setItem(LocalStorageVariables.REFRESH_TOKEN, refreshToken);
    }

    public refreshToken(): Observable<any> {
        const requestRefreshToken = this.getRefreshToken();
        if (requestRefreshToken) {
            return this.http.post<ILoginResponse>(`${this.ENDPOINT_V1}/User/Refresh?refreshToken=${requestRefreshToken}`, null).pipe(
                tap((loginResponse: ILoginResponse) => {
                    this.storeJwtToken(loginResponse.token);
                    this.storeRefreshToken(loginResponse.refreshToken);
                }));
        } else {
            return of(undefined);
        }
    }

    public updatePassword(filed: UpdatePasswordModel): Observable<User> {
        return this.http.patch<User>(`${this.ENDPOINT_V1}/User/ChangePassword`, filed);
    }

    public createNewPassword(filed: UpdatePasswordModel): Observable<User> {
        return this.http.patch<User>(`${this.ENDPOINT_V1}/User/UpdatePassword`, filed);
    }

    public checkRegistrationFields(info: RegistrationModel): Array<InfoFieldErrorModel> {
        const errors: Array<InfoFieldErrorModel> = [];
        errors.push(this.checkEmailField(info?.email));
        errors.push(this.checkPasswordField(info?.password));
        errors.push(this.checkConfirmedPasswordField(info?.password, info?.confirmedPassword));
        return errors;
    }

    public checkForgotPasswordFields(info: StringOrUndefined): Array<InfoFieldErrorModel> {
        const errors: Array<InfoFieldErrorModel> = [];
        errors.push(this.checkEmailField(info));
        return errors;
    }

    public checkLoginFields(info: LoginModel): Array<InfoFieldErrorModel> {
        const errors: Array<InfoFieldErrorModel> = [];
        errors.push(this.checkEmailField(info?.email));
        errors.push(this.checkPasswordField(info?.password));
        return errors;
    }

    public checkValidationManage(info: AccountData): Array<InfoFieldErrorModel> {
        if (info?.society === SocietyType.PERSONA_FISICA) {
            return this.checkAccountDataFieldsPerson(info);
        } else if (info?.society === SocietyType.IMPRESA_INDIVIDUALE) {
            return this.checkAccountDataFieldsSoloBusiness(info);
        } else {
            return this.checkAccountDataFields(info);
        }
    }

    public checkAccountDataFields(info: AccountData): Array<InfoFieldErrorModel> {
        const errors: Array<InfoFieldErrorModel> = [];
        errors.push(this.checkAttributesName(info?.name));
        errors.push(this.checkAttributeLastname(info?.surname));
        errors.push(this.checkAttributesPhone(info?.phone));
        errors.push(this.checkAttributesSociety(info?.society));
        errors.push(this.checkAttributesSector(info?.sector));
        errors.push(this.checkAttributesVatNumber(info?.vatNumber));
        errors.push(this.checkAttributesTaxIdCode(info?.taxIdCode));
        errors.push(this.checkAttributesBusinessName(info?.businessName));
        errors.push(this.checkAttributesPec(info?.pec));
        errors.push(this.checkAttributesUniqueCode(info?.uniqueCode));
        errors.push(this.checkAttributesCounty(info?.county));
        errors.push(this.checkAttributesCap(info?.cap));
        errors.push(this.checkAttributesLocation(info?.location));
        errors.push(this.checkAttributesAddress(info?.address));
        errors.push(this.checkAttributesDeliveryCounty(info?.deliveryCountry));
        errors.push(this.checkAttributesDeliveryCap(info?.deliveryCap));
        errors.push(this.checkAttributesDeliveryLocation(info?.deliveryLocation));
        errors.push(this.checkAttributesDeliveryAddress(info?.deliveryAddress));
        errors.push(this.checkAttributesHowKnowUs(info?.howKnowUs));
        return errors;
    }

    public checkAccountDataFieldsPerson(info: AccountData): Array<InfoFieldErrorModel> {
        const errors: Array<InfoFieldErrorModel> = [];
        errors.push(this.checkAttributesName(info?.name));
        errors.push(this.checkAttributeLastname(info?.surname));
        errors.push(this.checkAttributesPhone(info?.phone));
        errors.push(this.checkAttributesSociety(info?.society));
        errors.push(this.checkAttributesSector(info?.sector));
        errors.push(this.checkAttributesTaxIdCode(info?.taxIdCode));
        errors.push(this.checkAttributesCounty(info?.county));
        errors.push(this.checkAttributesCap(info?.cap));
        errors.push(this.checkAttributesLocation(info?.location));
        errors.push(this.checkAttributesAddress(info?.address));
        errors.push(this.checkAttributesHowKnowUs(info?.howKnowUs));
        return errors;
    }

    public checkAccountDataFieldsSoloBusiness(info: AccountData): Array<InfoFieldErrorModel> {
        const errors: Array<InfoFieldErrorModel> = [];
        errors.push(this.checkAttributesName(info?.name));
        errors.push(this.checkAttributeLastname(info?.surname));
        errors.push(this.checkAttributesPhone(info?.phone));
        errors.push(this.checkAttributesSociety(info?.society));
        errors.push(this.checkAttributesSector(info?.sector));
        errors.push(this.checkAttributesTaxIdCode(info?.taxIdCode));
        errors.push(this.checkAttributesVatNumber(info?.vatNumber));
        errors.push(this.checkAttributesCounty(info?.county));
        errors.push(this.checkAttributesCap(info?.cap));
        errors.push(this.checkAttributesLocation(info?.location));
        errors.push(this.checkAttributesAddress(info?.address));
        errors.push(this.checkAttributesHowKnowUs(info?.howKnowUs));
        return errors;
    }

    public checkBillingAddressInfo(info: AddressModel): Array<InfoFieldErrorModel> {
        const errors: Array<InfoFieldErrorModel> = [];
        errors.push(this.checkAttributesDescription(info?.reference));
        errors.push(this.checkAttributesRecipient(info?.name));
        errors.push(this.checkAttributesCounty(info?.stateCode));
        errors.push(this.checkAttributesCap(info?.zipCode));
        errors.push(this.checkAttributesLocation(info?.city));
        errors.push(this.checkAttributesAddress(info?.address));
        return errors;
    }

    public checkUpdatePasswordFields(info: UpdatePasswordModel): Array<InfoFieldErrorModel> {
        const errors: Array<InfoFieldErrorModel> = [];
        errors.push(this.checkOldPasswordField(info?.oldPassword));
        errors.push(this.checkPasswordField(info?.newPassword));
        errors.push(this.checkConfirmedPasswordField(info?.newPassword, info?.newPasswordConfirm));
        return errors;
    }

    public checkCreatePasswordFields(info: UpdatePasswordModel): Array<InfoFieldErrorModel> {
        const errors: Array<InfoFieldErrorModel> = [];
        errors.push(this.checkPasswordField(info?.newPassword));
        errors.push(this.checkConfirmedPasswordField(info?.newPassword, info?.newPasswordConfirm));
        return errors;
    }

    public checkNewNotificationFields(info: CommunicationResponseModel): Array<InfoFieldErrorModel> {
        const errors: Array<InfoFieldErrorModel> = [];
        errors.push(this.checkEmailField(info?.email));
        errors.push(this.checkAttributesMessage(info?.evento));
        return errors;
    }

    public getEmailFromJwt(): string {
        const jwt = localStorage.getItem(LocalStorageVariables.ACCESS_TOKEN);
        return jwt ? get(JSON.parse(atob(jwt.split('.')[1])), this.EMAIL_PATH_IN_JWT) : '';
    }

    public clearLocalStorage(): void {
        this.document?.defaultView?.localStorage?.removeItem(LocalStorageVariables.ACCESS_TOKEN);
        this.document?.defaultView?.localStorage?.removeItem(LocalStorageVariables.REFRESH_TOKEN);
    }

    public getUser$(): Observable<User> {
        return this._currentUserSubject$.asObservable();
    }

    public userValue(): User {
        return this._currentUserSubject$.value;
    }

    public setUserValue$(value: User): void {
        this._currentUserSubject$.next(value);
    }

    // private methods

    /* private checkIfFieldIsEmpty(value: StringOrUndefined, fieldName: string): InfoFieldErrorModel {
         const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, fieldName);
         const vatFieldValidations = [ValidationFormNames.REQUIRED];
         const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(vatFieldValidations, value);
         if (!isEmpty(errors)) {
             info.hasError = true;
             info.errorMessage = errors;
         }
         return info;
     }*/

    private checkAttributesDescription(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, ShippingAddressEnum.DESCRIPTION);
        const nameFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(nameFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesRecipient(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, ShippingAddressEnum.RECIPIENT);
        const nameFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(nameFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesName(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.NAME);
        const nameFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(nameFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributeLastname(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.LASTNAME);
        const lastnameFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(lastnameFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesPhone(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.PHONE);
        const phoneFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(phoneFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesVatNumber(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.VAT);
        const vatFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(vatFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesTaxIdCode(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.TAX_ID_CODE);
        const taxIdFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(taxIdFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesBusinessName(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.BUSINESS_NAME);
        const businessNameFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(businessNameFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesPec(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.PEC);
        const pecFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(pecFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesUniqueCode(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.UNIQUE_CODE);
        const uniqueCodeFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(uniqueCodeFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesCounty(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.COUNTY);
        const countryFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(countryFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesDeliveryCounty(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.COUNTY_DELIVERY_ADDRESS);
        const countryFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(countryFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesCap(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.CAP);
        const capFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(capFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesDeliveryCap(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.CAP_DELIVERY_ADDRESS);
        const capFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(capFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesLocation(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.LOCATION);
        const locFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(locFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesDeliveryLocation(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.LOCATION_DELIVERY_ADDRESS);
        const locFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(locFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesAddress(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.ADDRESS);
        const addressFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(addressFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesDeliveryAddress(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.ADDRESS_DELIVERY_ADDRESS);
        const addressFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(addressFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesSociety(value: NumberOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.SOCIETY);
        const societyFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<NumberOrUndefined>(societyFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesSector(value: NumberOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.SECTOR);
        const sectorFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<NumberOrUndefined>(sectorFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesHowKnowUs(value: NumberOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, AccountDataEnum.HOW_KNOW_US);
        const howKnowUsFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<NumberOrUndefined>(howKnowUsFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkAttributesMessage(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, MessageField.MESSAGE);
        const mailFieldValidations = [ValidationFormNames.REQUIRED];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(mailFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkEmailField(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, RegistrationFieldsEnum.EMAIL);
        const mailFieldValidations = [ValidationFormNames.REQUIRED, ValidationFormNames.EMAIL];
        const errors: Array<string> = ValidationFormConfig.getInstance().getErrorsFromField<StringOrUndefined>(mailFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkPasswordField(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, RegistrationFieldsEnum.PASSWORD);
        const passwordFieldValidations = [ValidationFormNames.REQUIRED, ValidationFormNames.PASSWORD];
        const errors: Array<string> = ValidationFormConfig
            .getInstance()
            .getErrorsFromField<StringOrUndefined>(passwordFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkOldPasswordField(value: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, UpdatePasswordEnum.OLD_PASSWORD);
        const passwordFieldValidations = [ValidationFormNames.REQUIRED, ValidationFormNames.PASSWORD];
        const errors: Array<string> = ValidationFormConfig
            .getInstance()
            .getErrorsFromField<StringOrUndefined>(passwordFieldValidations, value);
        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private checkConfirmedPasswordField(password: StringOrUndefined, confirmedPassword: StringOrUndefined): InfoFieldErrorModel {
        const info: InfoFieldErrorModel = new InfoFieldErrorModel(false, RegistrationFieldsEnum.CONFIRMED_PASSWORD);
        const EqualPasswordRequest: IEqualPasswordValidationRequest = {password, confirmedPassword};
        const errorRequired: string = ValidationFormConfig
            .getInstance()
            .getValidationFormMap<StringOrUndefined>(password)
            .get(ValidationFormNames.REQUIRED)?.(password) ?? '';
        const errorEqualPassword: string = ValidationFormConfig
            .getInstance()
            .getValidationFormMap<IEqualPasswordRequestOrUndefined>(EqualPasswordRequest)
            .get(ValidationFormNames.EQUAL_PASSWORD)?.(EqualPasswordRequest) ?? '';

        const errors = [errorRequired, errorEqualPassword].filter(el => el);

        if (!isEmpty(errors)) {
            info.hasError = true;
            info.errorMessage = errors;
        }
        return info;
    }

    private getRefreshToken() {
        return this.document?.defaultView?.localStorage?.getItem(LocalStorageVariables.REFRESH_TOKEN);
    }
}
