import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-custom-chip',
    templateUrl: './custom-chip.component.html',
    styleUrls: ['./custom-chip.component.scss']
})
export class CustomChipComponent implements OnInit, OnChanges {
    @Input() text: string;
    @Input() color: string;
    @Input() backgroundColor: string;
    @Input() backgroundColorWhite = false;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.text = changes?.text?.currentValue ?? this.text;
        this.color = changes?.color?.currentValue ?? this.color;
        this.backgroundColor = changes?.backgroundColor?.currentValue ?? this.backgroundColor;
        this.backgroundColorWhite = changes?.backgroundColorWhite?.currentValue ?? this.backgroundColorWhite;
    }

    ngOnInit(): void {
    }
}
