<div id="row-box-image" class="row">
    <div class="border-div">
        <div class="container-icon border-0" *ngIf="icon">
            <div class="icon-video-call">
                <img [src]="icon" class="material-icons" alt="svg icon" />
            </div>
        </div>
        <div class="full-banner text-center p-left box-video-call">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="text-center">
                            <h2 class="my-3 font-weight-400">{{title}}</h2>
                            <p>{{text}}</p>
                            <button class="btn btn-solid" (click)="goToLink(button_link)" type="submit" *ngIf="button_text">{{button_text}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>