import {Component, Input} from '@angular/core';
import {IInfoPanel} from '../../models/interfaces/IInfoPanel';

@Component({
    selector: 'app-custom-card',
    templateUrl: './custom-card.component.html',
    styleUrls: ['./custom-card.component.scss']
})
export class CustomCardComponent {

  @Input() cards: Array<IInfoPanel> = [];
}
