import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-feedback-modal',
    templateUrl: './feedback-modal.component.html',
    styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent {

    // Proprietà di input
    title: string;
    subTitle: string;
    textOkButton = 'OK';
    textCancelBtn = 'GENERAL.CANCEL';
    visibleCancelBtn: boolean;
    icon: string;

    constructor(public translateService: TranslateService,
                public activeModal: NgbActiveModal) {
    }

    public successAction(): void {
        this.activeModal.close();
    }

    public cancelAction(): void {
        this.activeModal.dismiss();
    }
}
