<!--modal popup start-->
<ng-template class="theme-modal" #newsletter let-modal #newsLetter>
    <div class="modal-content">
        <div class="modal-body modal7">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg p-0">
                            <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="text-align-last-center pt-2">
                                <h2>{{ 'NEWSLETTER.PRESENT' | translate }}</h2>
                            </div>
                            <div class="offer-content">
                                <div class="d-flex justify-content-center align-items-center text-lowercase">
                                    <h1 class="text-align-last-center color-default" style="font-size: 3.5rem !important"> - 25 €</h1>
                                </div>
                                <div class="d-flex justify-content-center align-items-center text-lowercase">
                                    <b class="text-align-last-center ms-1 text-lowercase font-weight-400 color-grey font-size-16">{{ 'NEWSLETTER.DISCOUNT_COUPON' | translate }}</b>
                                </div>
                                <h6 class="text-align-last-center font-bold mt-3 font-weight-700 text-dark">{{ 'NEWSLETTER.REGISTER_AND_SUBSCRIBE' | translate }}</h6>
                                <form action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda"
                                      class="auth-form needs-validation" method="post" id="mc-embedded-subscribe-form"
                                      name="mc-embedded-subscribe-form" target="_blank">
                                    <div class="form-group mx-sm-3">
                                        <button routerLink="/auth/registration" [queryParams]="{status: 'checked'}" (click)="modal.dismiss('Cross click')" type="submit" class="btn btn-solid"
                                                id="mc-submit"> {{ 'NEWSLETTER.START_NOW' | translate }}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-modal-newsletter">
                <input type="checkbox" (change)="dontEverShow()" class="mb-1 me-2"
                       id="showNL">{{ 'GENERAL.DONT_SHOW' | translate }}
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->
